<!-- TITULO -->
<section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Aprovação Visita {{this.tpAcaoAtividade}}</h2>
      <app-btn-back></app-btn-back>
    </div>
  </section>

  <section class="container-conviva form-registro">
    <!-- VISUALIZACAO GERAL -->
    <div class="container form-vista-psicologo visualizacao-geral">

      <div class="grupo-filter-full">
        <label>Psicólogo:</label>
        <p>{{this.atividade.nome}}</p>
      </div>

      <div class="conteudo-row">
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Data da Visita:</label>
            <p>{{getDate(this.atividade.dT_ATIVIDADE_PSICOLOGO)}}</p>
          </div>
        </div>
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Quanto tempo durou a ação?</label>
            <p>{{this.atividade.tM_ATIVIDADE_PSICOLOGO}}MN</p>
          </div>
        </div>
      </div>

      <div class="grupo-filter-full">
        <label>Ocorrências agendadas:</label>
        <p>Chamado {{this.atividade.cD_CHAMADO}} - {{this.atividade.dS_SUB_CATEGORIA}} -
          {{this.atividade.statuS_CHAMADO}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Qual atividade foi realizada?</label>
        <p>{{this.atividade.tP_ACAO_ATIVIDADE == 5 ?
           this.atividade.dS_RESPOSTA_PERGUNTA_OBJETIVO_ACAO :
           this.atividade.atividadE_REALIZADA}}</p>
      </div>

      <div class="conteudo-envolvidos" *ngIf="this.atividade.tP_ATIVIDADE_REALIZADA == 1 && this.atividade.cD_ENVOLVIDO != null">
        <h3>Envolvido</h3>
        <div class="envolvidos-aluno">
          <div class="perfil-aluno-chamado" *ngFor="let envolvido of lstEnvolvidos ">
            <div class="img-aluno">
              <span>{{envolvido.iniciais}}</span>
            </div>
            <div class="infor-aluno-chamado">
              <p class="nome-aluno">{{envolvido.nome}}</p>
              <p class="descricao">{{envolvido.dsTipoPessoa}} - {{envolvido.dsEnvolvido}}</p>
            </div>
          </div>
        </div>
      </div>


      <div class="conteudo-envolvidos" *ngIf="this.atividade.tP_ATIVIDADE_REALIZADA == 2" >
        <h3>Turmas</h3>
        <div class="grid-datatable">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border"
            aria-describedby="dashboard"
          >
            <thead>
              <tr>
                <th>Codigo Turma</th>
                <th>Numero Classe</th>
                <th>Tipo de Ensino</th>
                <th>Numero de Serie</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstTurmas">
                <td>{{item.cD_TURMA}}</td>
                <td>{{item.nR_CLASSE}}</td>
                <td>{{item.dS_TURMA}}</td>
                <td>{{item.nR_SERIE}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      @if(this.atividade.nM_DSL_DIRETORIA_DESTINO != null){
        <h3 class="sub-titulo-dash">Deslocamento:</h3>
        <div class="conteudo-row">
          <div class="box-duas-colum">
            <div class="grupo-filter-full">
              <label><b>Origem</b></label>
              <p><b>Diretoria:</b>{{this.atividade.nM_DSL_DIRETORIA_ORIGEM}}</p>
              <p><b>Escola: </b>{{this.atividade.nM_DSL_ESCOLA_ORIGEM}}</p>
              <p><b>Horário saída: </b>{{this.atividade.hR_DSL_SAIDA}}</p>
            </div>
          </div>
          <div class="box-duas-colum">
            <div class="grupo-filter-full">
              <label><b>Destino</b></label>
              <p><b>Diretoria: </b></p>
              <p><b>Escola: </b>{{this.atividade.nM_DSL_ESCOLA_DESTINO}}</p>
              <p><b>Horário chegada: </b>{{this.atividade.hR_DSL_CHEGADA}}</p>
              <p><b>Tempo de Deslocamento:</b> {{getTempoDeslocamento(atividade.hR_DSL_SAIDA, atividade.hR_DSL_CHEGADA)}}</p>
            </div>
          </div>
        </div>
      }

      <div class="grupo-filter-full">
        <label>Anexos</label>
        @for (item of this.atividade.arquivos; track $index) {
          <a
          href="javascript:void(0)"
          class="icon-arquivo no-wrap"
          (click)="abrirEmNovaAba(item.caminhoAzure)"
          style="white-space: nowrap;"
        >
        {{ getShortenedName(item.nome) }}
        </a>
        }
    </div>
    </div>
