
<form [formGroup]="formFilter">
  <div class="table-buttons">

      <div class="filtros">
        <!--Painel Geral -->
        @if (this.tipoRelatorio == 0)
        {
        <select id="filter-field" formControlName="filterField">
          <option></option>
          <option value="codigoChamado">Chamado</option>
          <option value="nomeGravidade">Gravidade</option>
          <option value="nomeCategoria">Categoria</option>
          <option value="nomeSubCategoria">Subcategoria</option>
          <option value="dsStatus">Status</option>
          <option value="nomeEscola">Escola</option>
        </select>
        }
        <!--Painel Avaliação Fiscal -->
        @if(this.tipoRelatorio == 1)
        {
          <select id="filter-field" formControlName="filterField">
            <option></option>
            <option value="codigoAtividadePsicologo">Código</option>
            <option value="nomeDiretoria">Diretoria</option>
            <option value="nomeEscola">Escola</option>
            <option value="deslocamentoEscolaOrigem">Escola Origem</option>
            <option value="nomePsicologo">Psicólogo</option>
            <option value="descricaoTipoAcao">Tipo Ação</option>
            <option value="ocorrenciaAgendada">Ocorrencia</option>
            <option value="descricaoAtividadeRealizada">Tipo Ação</option>
            <option value="descricaoValidacaoDiretor">Validação Diretor</option>
          </select>
        }
        <!--Painel Avaliação Fiscal -->
        @if(this.tipoRelatorio == 2)
        {
          <select id="filter-field" formControlName="filterField">
            <option></option>
            <option value="codigoChamado">Nº Chamado</option>
            <option value="nomeEnvolvido">Nome Envolvido</option>
            <option value="tipoEnvolvimento">Tipo Envolvido</option>
            <option value="escola">Escola</option>
            <option value="psicologo">Psicólogo</option>
          </select>
        }        
        <select id="filter-type" formControlName="filterType">
          <option value="=">=</option>
          <option value="<"><</option>
          <option value="<="><=</option>
          <option value=">">></option>
          <option value=">=">>=</option>
          <option value="!=">!=</option>
          <option value="like">like</option>
        </select>
    
        <input id="filter-value" type="text" formControlName="filterValue" (keyup)="filtarValue()">
    
        <button id="filter-clear" (click)="clearFilters()">Limpar filtro</button>
      </div>
    
  
    <div class="buttons">
      <button type="button" class="btn-header impressao" (click)="ImprimirTabela()">Imprimir</button>
      <button type="button" class="btn-header excel" (click)="ExportComoExcel()">Excel</button>
      <button type="button" class="btn-header pdf" (click)="ExportarComoPDF()">PDF</button>
    </div>
  </div>
  <div id="table-conviva"></div>
</form>



