//Principais
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { DataTableDirective } from "angular-datatables";

//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { Funcionario } from 'src/app/shared/models/envolvidos/funcionario';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';
import { AtividadePsicologo } from 'src/app/shared/models/psicologo/AtividadePsicologo';
import { TipoPessoa } from 'src/app/shared/enums/envolvidos/TipoPessoa';

//Serviços
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { FuncionarioService } from 'src/app/shared/services/funcionarios.service';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { ActivatedRoute } from '@angular/router';
import { AtividadePsicologoDeslocamento } from 'src/app/shared/models/atividade-psicologo/atividade-psicologo-deslocamento';

@Component({
  templateUrl: './psicologo-visita.acolhimento.html',
  styleUrls: ['./psicologo-visita.acolhimento.scss']
})

export class PageVisitaPsicologoacolhimentoComponent implements OnInit, OnDestroy {

  @Input({ required: true }) temDeslocamento: boolean;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  // Grid Datatable
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public isDtInitialized:boolean = false;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private psicologoService:PsicologoService,
    private arquivoService: ArquivoService,
    private chamadoService: ChamadoService,
    private envolvidoService:EnvolvidoService,
    private funcionarioService: FuncionarioService,
    private genericValidate: GenericValidator,
    private route: ActivatedRoute,
    private genericValidator: GenericValidator,
  ) {
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }

    this.route.params.subscribe((params) => {
      if(params.temDeslocamento === '1'){
        this.temDeslocamento = true;
      }
      else {
        this.temDeslocamento = false;
      }
    });
  }

  // Passos de Proximo
  public Passo:number = 1;

  // Objetos e Filtros
  public polos: Polo[] = [];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public psicologos: Psicologo[] = [];
  public lstPsicologos: Psicologo[] = [];
  public lstTurmas: Turma[] = [];
  public lstTurmasSelecionadas: Turma[] = [];
  public lstTurmasAtivas: number[] = [];
  public lstTurmaAluno: TurmaAluno[] = [];
  public lstFuncionariosSelecionados: Funcionario[] = [];
  public lstOutros: string[] = [];
  public lstFuncionarios: Funcionario[] = [];
  public lstOcorrenciasAgendadas: ListarChamados[] = [];
  public OcorrenciaSelecionada: ListarChamados;
  public files: ArquivoAzure[] = [];
  public envolvidos: Envolvido[] = [];
  public lstEnvolvidos: Envolvido[] = [];
  public lstEnvolvidosSelecionados: Envolvido[] = [];
  public modelDeslocamento: AtividadePsicologoDeslocamento | null;

  public envolvidoExclusao?: Envolvido;
  public NomePsicologo?:string;
  public showGridTurma:boolean;
  public mostrarAlunos = false;
  public mostrarGrupos = false;
  public mostrarProffisionais = false;
  public showModalGrupos = false;
  public mostrarDivTurmas = false;
  public mostrarDivEquipeEscolar = false;
  public mostrarDivOutros = false;
  public showDropAuxiliar = false;
  public submittedModal = false;
  public showModal: boolean = false;
  public showModalEquipeEscolar = false;
  public showModalOutros = false;
  public showModalExcluirEnvolvido = false;
  public showDropFuncionarios: boolean = false;
  public descricaoExclusao = '';
  public duracaoVisita = 15;
  public nomePsicologoSelecionado?: string;
  public dataVisita?: Date;
  public ocorrenciaAgendadaSelecionado?: string;
  public atividadeRealizadaSelecionado?: string;
  public objetivoAcao?: string;
  public avaliacaoResultado?: string;
  public impactoAcao?: string;
  public enableEnviarAprovacao: boolean = false;
  public qtdeCaracteresAvalia:number = 0;
  public qtdeCaracteresImpacto:number = 0;

  public lstAtividadeRealizada = [
    { id: 1, name: "Conversa/atividade individual com estudante" },
    { id: 2, name: "Conversa/atividade em grupo" },
    // { id: 3, name: "Conversa/atividade com profissionais" }
  ];

  public lstGrupos = [
    {id: 1, name:"Turmas participantes da atividade" },
    {id: 2, name:"Equipe escolar" },
    {id: 3, name:"Outros" }
  ];

  public tipoPessoaAdd = [
    {descricao: 'Outros', id: 1},
    {descricao: 'Profissional', id: 2}
  ];

  // Forms
  public formVisita!: FormGroup;
  public formVisitaAlunos!: FormGroup;
  public formModalEquipeEscolar!: FormGroup;
  public formModalOutros!: FormGroup;
  public formModal!: FormGroup;

  public get form() { return this.formVisita.controls; }
  public get formAluno() { return this.formVisitaAlunos.controls; }
  public get formEquipeEscolar() { return this.formModalEquipeEscolar.controls; }
  public get formOutros() { return this.formModalOutros.controls; }
  public get formModalControls() { return this.formModal.controls; }

  public submitted: boolean = false;

  public async ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'pdf',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        }
      ],
    };

    //#region Forms

    this.formVisita = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      nomeDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      nomeEscola: new FormControl(null, [Validators.required]),
      codigoPsicologo: new FormControl(null, [Validators.required]),
      dataVisita: new FormControl(null, [Validators.required]),
      codigoAtividade: new FormControl(null, [Validators.required]),
      codigoAcaoPreventiva: new FormControl(null, [Validators.required]),
      tipoGrupo: new FormControl(null, [Validators.required]),
      codigoChamado: new FormControl(null, [Validators.required]),
      objetivoAcao: new FormControl(null, [Validators.required]),
      avaliacaoResultado: new FormControl(null, [Validators.required]),
      impactoAcao: new FormControl(null, [Validators.required]),
      arquivos: new FormControl(null, [Validators.required]),
    });

    this.formVisita = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      nomeDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      nomeEscola: new FormControl(null, [Validators.required]),
      codigoPsicologo: new FormControl(null, [Validators.required]),
      dataVisita: new FormControl(null, [Validators.required]),
      codigoAtividade: new FormControl(null, [Validators.required]),
      codigoAcaoPreventiva: new FormControl(null, [Validators.required]),
      tipoGrupo: new FormControl(null, [Validators.required]),
      codigoChamado: new FormControl(null, [Validators.required]),
      objetivoAcao: new FormControl(null, [Validators.required]),
      avaliacaoResultado: new FormControl(null, [Validators.required]),
      impactoAcao: new FormControl(null, [Validators.required]),
      arquivos: new FormControl(null, [Validators.required]),
    });

    this.formVisitaAlunos = new FormGroup({
      presenca: new FormControl(null),
      atendimentoClinico: new FormControl(null),
      encaminhamentoRedeSaude: new FormControl(null)
    });

    this.formModal = new FormGroup({
      tipoPessoa: new FormControl(null),
      cpfBusca: new FormControl(null, [Validators.required, this.genericValidator.ValidaCpf]),
      cpfProfissionalSelecionado: new FormControl(null),
      dsOutros: new FormControl(null)
    });

    //#endregion

    if(this.userLogado?.flPsicologo){
      await this.getPoloDiretoriaPsicologo();
    }
    else{
        if( this.atribuicoesLogin.NumeroComportamento == 1) {
          await this.getPolosAdmin();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 2) {
          await this.getPolosPerfilDiretor();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
          await this.getPolosPerfilProfessor();
        }
    }

    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0) {
      await this.getDiretoriasPorPolo();
      await this.getEscolasPorDiretoria();
    }
  }

  public ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  public async getPsicologosPolo() {
    if(this.userLogado?.codigoPerfil == 1634) { //Codigo Perfil Psicologo
      this.spinnerService.show();
      const response = await lastValueFrom(this.psicologoService.getPsicologoPorCPF(this.userLogado.cpf))
                            .catch((err) => {
                              return this.ConvivaErrors.handleResultError(err);
                            });

      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.spinnerService.hide();

      this.lstPsicologos = [];

      let psicologo: Psicologo = new Psicologo;
      psicologo.nomePsicologo = response.data.nome;
      psicologo.codigoPsicologo = response.data.codigoPsicologo;

      this.lstPsicologos.push(psicologo);
    }
    else {
      this.spinnerService.show();
      const response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.formVisita.get('codigoPolo')!.value))
                            .catch((err) => {
                              return this.ConvivaErrors.handleResultError(err);
                            });

      if(!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.spinnerService.hide();
      this.lstPsicologos = response.data;
    }
  }

  //#region Polos

  public async getPoloDiretoriaPsicologo() {
    this.spinnerService.show();
    const response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
    await this.getEscolasPorDiretoria();

    await this.getPsicologosPolo();
  }

  public async getPolosAdmin() {
    this.spinnerService.show();

    const response = await lastValueFrom(this.psicologoService.getPolos())
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = response.data;

    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0)
      this.ativaPolo();

    this.spinnerService.hide();

    await this.getPsicologosPolo();
  }

  public async getPolosPerfilDiretor(){
    this.spinnerService.show();

    const response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if(this.userLogado?.DadosUsuario[0].CodigoDiretoria && this.userLogado?.DadosUsuario[0].CodigoDiretoria > 0) {
      let todasDiretoriasDoUsuario:Diretoria[] = [];

      this.userLogado?.DadosUsuario.forEach(item=>{
        let diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria) ;
      });

      this.diretorias = todasDiretoriasDoUsuario;

      if(this.diretorias.length == 1)
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      else
        this.form.codigoDiretoria.enable();


      if(this.userLogado?.codigoDiretoria && this.userLogado.codigoDiretoria != 0){
        this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
      }

      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor(){
    this.spinnerService.show();

    const response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    const dadosUsuario = this.userLogado?.DadosUsuario[0];
    if(dadosUsuario?.CodigoDiretoria && dadosUsuario?.CodigoDiretoria > 0 && dadosUsuario?.CodigoEscola && dadosUsuario?.CodigoEscola > 0) {

      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }

    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  public ativaPolo() {
    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0)
      this.formVisita.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);

    if(this.userLogado)
      this.authService.setUser(this.userLogado);
  }

  public async cambioPolo() {
    const codigoPO = this.formVisita.get('codigoPolo')?.value;

    if(this.userLogado) {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }

    this.form.codigoDiretoria.enable();
    this.form.codigoEscola.enable();

    this.zerarEscola();
    await this.getDiretoriasPorPolo();
  }

  //#endregion

  //#region Diretorias

  public async getDiretoriasPorPolo() {
    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0) {

      this.zerarDiretoria();
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();

      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();

      this.ativaDiretoria();
      this.spinnerService.hide();
    }

    if(this.userLogado) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria ;
      this.authService.setUser(this.userLogado);
    }
  }

  public ativaDiretoria(){
    if(this.diretorias && this.userLogado?.codigoDiretoria != 0) {

      const diretoria = this.diretorias.find(s=>s.codigoDiretoria == this.userLogado?.codigoDiretoria);

      if(this.userLogado?.codigoDiretoria  && diretoria)
        this.formVisita.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    }

    if(this.userLogado)
        this.authService.setUser(this.userLogado);
  }

  public zerarDiretoria() {
    this.diretorias = [];
    this.formVisita.get('codigoDiretoria')?.patchValue(null);
  }

  //#endregion

  //#region Escolas

  public async getEscolasPorDiretoria() {
    if(this.userLogado) {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if(this.userLogado?.codigoDiretoria && this.userLogado?.codigoDiretoria > 0) {
      this.spinnerService.show();
      this.zerarEscola();

      const response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.escolas = response.data;
      this.ativaEscola();
      this.spinnerService.hide();
    }

  }

  public ativaEscola() {
    if(this.escolas && this.userLogado?.codigoEscola && this.userLogado?.codigoEscola > 0) {

      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola);

      if(this.userLogado?.codigoEscola && escola)
        this.formVisita.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formVisita.get('codigoEscola')?.patchValue(null);
  }

  public async trocaDiretoria() {
    const codigoDiretoria = this.formVisita.get('codigoDiretoria')?.value;

    if(this.userLogado && codigoDiretoria) {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }

    if(codigoDiretoria)
      await this.getEscolasPorDiretoria();
    else{
      this.escolas = [];
      this.form.codigoEscola.setValue(null);
    }
  }

  //#endregion

  //#region Salvar/Limpar

  private mappingArrayEnvolvidos(): Envolvido[] {
    var envolvidosNaAtividade: Envolvido[] = [];
    var tipoAtividade: number = this.formVisita.get('codigoAtividade')?.value;

    if (tipoAtividade == 3) { //tipo grupo

      const funcionarios = this.lstFuncionariosSelecionados.map((x: Funcionario) => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.cpf = x.cpf;
        envolvidoAtv.tipoPessoa = TipoPessoa.EquipeEscolar;
        return envolvidoAtv;
      });

      const outros = this.lstOutros.map(x => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.dsOutros = x;
        envolvidoAtv.tipoPessoa = TipoPessoa.Outros;
        return envolvidoAtv;
      });
      envolvidosNaAtividade = funcionarios.concat(outros);
    }
    return envolvidosNaAtividade;
  }

  public async OnSalvar() {
    this.spinnerService.show();

    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.formVisita.get('codigoDiretoria')?.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.formVisita.get('codigoEscola')?.value)[0];
    const codigoAtividade = this.formVisita.get('codigoAtividade')?.value;

    if(codigoAtividade == 3) {
      this.lstEnvolvidosSelecionados = this.mappingArrayEnvolvidos();
    }

    const objAtendimentoPsicologo: AtividadePsicologo = {
      codigoPolo: this.formVisita.get('codigoPolo')?.value,
      codigoDiretoria: this.formVisita.get('codigoDiretoria')?.value,
      nomeDiretoria: dir.nomeDiretoria,
      codigoEscola: this.formVisita.get('codigoEscola')?.value,
      nomeEscola: esc.nomeEscola,
      codigoPsicologo: this.formVisita.get('codigoPsicologo')?.value,
      codigoChamado: this.formVisita.get('codigoChamado')?.value,
      dataAtividadePsicologo: this.formVisita.get('dataVisita')?.value,
      timeAtividadePsicologo: this.duracaoVisita.toString(),
      tipoAcaoAtividade: 2, //Acolhimento Psicologo
      tipoAtividadeRealizada: codigoAtividade,
      descricaoRespostaPerguntaObjetivoAcao: this.formVisita.get('objetivoAcao')?.value,
      descricaoRespostaPerguntaAvaliacaoResultadoAcao: this.formVisita.get('avaliacaoResultado')?.value,
      descricaoRespostaPerguntaImpactoAcaoRealizada: this.formVisita.get('impactoAcao')?.value,
      tipoAcao: 1, //Inserir
      codigoUsuario: this.userLogado?.flPsicologo ? this.userLogado.codigoUsuario : this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.userLogado?.flPsicologo ? this.userLogado.codigoPerfil : this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.userLogado?.flPsicologo ? this.userLogado.nomePerfil : this.atribuicoesLogin.NomePerfil,
      arquivos: this.files,
      listaEnvolvidos: this.lstEnvolvidosSelecionados
    };

    if(this.temDeslocamento) {
     objAtendimentoPsicologo.codigoDeslocamentoDiretoriaOrigem = this.modelDeslocamento?.codigoDiretoriaOrigem;
     objAtendimentoPsicologo.nomeDeslocamentoDiretoriaOrigem = this.modelDeslocamento?.nomeDiretoriaOrigem;
     objAtendimentoPsicologo.codigoDeslocamentoEscolaOrigem = this.modelDeslocamento?.codigoEscolaOrigem;
     objAtendimentoPsicologo.nomeDeslocamentoEscolaOrigem = this.modelDeslocamento?.nomeEscolaOrigem;
     objAtendimentoPsicologo.codigoDeslocamentoDiretoriaDestino = this.modelDeslocamento?.codigoDiretoriaDestino;
     objAtendimentoPsicologo.nomeDeslocamentoDiretoriaDestino = this.modelDeslocamento?.nomeDiretoriaDestino;
     objAtendimentoPsicologo.codigoDeslocamentoEscolaDestino = this.modelDeslocamento?.codigoEscolaDestino;
     objAtendimentoPsicologo.nomeDeslocamentoEscolaDestino = this.modelDeslocamento?.nomeEscolaDestino;
     objAtendimentoPsicologo.codigoTipoTransporte = this.modelDeslocamento?.codigoTipoTransporte;
     objAtendimentoPsicologo.horaDeslocamentoSaida = this.modelDeslocamento?.horarioSaida.substring(0,5);
     objAtendimentoPsicologo.horaDeslocamentoChegada = this.modelDeslocamento?.horarioChegada.substring(0,5);
    }

    const responseCadastro = await lastValueFrom(this.psicologoService.setCadastrarAtividadePsicologo(objAtendimentoPsicologo))
                                  .catch((err) => {
                                    this.spinnerService.hide();
                                    return this.ConvivaErrors.handleResultError(err);
                                  });

    if(!responseCadastro.isSucess) {
      this.toastr.error(responseCadastro.message);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Visita do Psicologo cadastrada com sucesso.');
    this.toastr.success('Redirecionando para o ínicio em 5 segundos.');
    this.enableEnviarAprovacao = false;

    setTimeout(() => {
      window.location.reload();
    }, 5000);

    this.spinnerService.hide();
  }

  public async OnLimpar() {
    this.spinnerService.show();

    this.formVisita.get('codigoPsicologo')?.patchValue(null);
    this.formVisita.get('dataVisita')?.patchValue(null);
    this.formVisita.get('codigoChamado')?.patchValue(null);
    this.formVisita.get('codigoAtividade')?.patchValue(null);
    this.formVisita.get('codigoAcaoPreventiva')?.patchValue(null);
    this.formVisita.get('tipoGrupo')?.patchValue(null);
    this.formVisita.get('objetivoAcao')?.patchValue(null);
    this.formVisita.get('avaliacaoResultado')?.patchValue(null);
    this.formVisita.get('impactoAcao')?.patchValue(null);

    this.files = [];

    await this.getPsicologosPolo();
    this.OnLimparEnvolvidos();
    this.spinnerService.hide();
  }

  public OnLimparEnvolvidos() {
    this.spinnerService.show();
    this.lstTurmas = [];
    this.lstTurmasAtivas = [];
    this.lstTurmaAluno = [];
    this.lstEnvolvidos = [];
    this.lstTurmasAtivas = [];
    this.lstFuncionarios = [];
    this.lstFuncionariosSelecionados = [];
    this.lstEnvolvidosSelecionados = [];
    this.files = [];
    this.envolvidos = [];
    this.showGridTurma = false;
    this.mostrarDivEquipeEscolar = false;
    this.mostrarDivTurmas = false;
    this.mostrarDivOutros = false;
    this.spinnerService.hide();
  }

  public proximoPassoDeslocamento(modelDeslocamento: AtividadePsicologoDeslocamento) {
    this.modelDeslocamento = modelDeslocamento;
    this.Passo = 2; // Proximo passo do acolhimento
  }

  public setNovoPasso(novoPasso:number) {
    var passoAvaliar = novoPasso - 1 - (this.temDeslocamento ? 1 : 0);

    if(!this.ValidaCampos(passoAvaliar)){
      this.toastr.error("Há campos não preenchidos");
      this.spinnerService.hide();
      return;
    }

    if(novoPasso == 3 || (this.temDeslocamento && novoPasso == 4)) {
      this.objetivoAcao = this.formVisita.get('objetivoAcao')?.value;
      this.avaliacaoResultado = this.formVisita.get('avaliacaoResultado')?.value;
      this.impactoAcao = this.formVisita.get('impactoAcao')?.value;
    }

    this.Passo = novoPasso;
  }

  public ValidaCampos(aba: number){
    this.submitted = true;
    if(aba == 1) {
      this.ajusteValidacaoInfoPsicologos();

      if (this.formVisita.invalid)
        return false;

      if(this.duracaoVisita && this.duracaoVisita <= 0) {
        this.toastr.error("A Duração da Visita precisa ser maior que zero.");
        return false;
      }
    }

    if(aba == 2){
      this.ajusteValidacaoInfoAcoes();
      if(this.files.length == 0){ return false; }
      if (this.formVisita.invalid)
        return false;
    }

    return true;
  }

  public setVoltarPasso(PassoAtual:number) {
    if(PassoAtual - 1 > 0) {
      this.Passo = PassoAtual - 1;
    }
    else {
      this.toastr.info('Não pode voltar da primeira pagina ...');
    }
  }

  public ajusteValidacaoInfoPsicologos() {
    if (this.formVisita) {
      this.ajusteValidacaoTodoForm();

      this.formVisita.get('nomeDiretoria')?.removeValidators([Validators.required]);
      this.formVisita.get('nomeDiretoria')?.updateValueAndValidity();
      this.formVisita.get('nomeEscola')?.removeValidators([Validators.required]);
      this.formVisita.get('nomeEscola')?.updateValueAndValidity();

      this.formVisita.get('codigoAcaoPreventiva')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoAcaoPreventiva')?.updateValueAndValidity();

      this.formVisita.get('tipoGrupo')?.removeValidators([Validators.required]);
      this.formVisita.get('tipoGrupo')?.updateValueAndValidity();

      this.formVisita.get('objetivoAcao')?.removeValidators([Validators.required]);
      this.formVisita.get('objetivoAcao')?.updateValueAndValidity();

      this.formVisita.get('avaliacaoResultado')?.removeValidators([Validators.required]);
      this.formVisita.get('avaliacaoResultado')?.updateValueAndValidity();

      this.formVisita.get('impactoAcao')?.removeValidators([Validators.required]);
      this.formVisita.get('impactoAcao')?.updateValueAndValidity();

      this.formVisita.get('arquivos')?.removeValidators([Validators.required]);
      this.formVisita.get('arquivos')?.updateValueAndValidity();
    }
  }

  public ajusteValidacaoInfoAcoes() {
    if (this.formVisita) {
      this.ajusteValidacaoTodoForm();

      this.formVisita.get('codigoPolo')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoPolo')?.updateValueAndValidity();

      this.formVisita.get('codigoDiretoria')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoDiretoria')?.updateValueAndValidity();
      this.formVisita.get('nomeDiretoria')?.removeValidators([Validators.required]);
      this.formVisita.get('nomeDiretoria')?.updateValueAndValidity();

      this.formVisita.get('codigoEscola')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoEscola')?.updateValueAndValidity();
      this.formVisita.get('nomeEscola')?.removeValidators([Validators.required]);
      this.formVisita.get('nomeEscola')?.updateValueAndValidity();

      this.formVisita.get('codigoPsicologo')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoPsicologo')?.updateValueAndValidity();

      this.formVisita.get('dataVisita')?.removeValidators([Validators.required]);
      this.formVisita.get('dataVisita')?.updateValueAndValidity();

      this.formVisita.get('codigoAtividade')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoAtividade')?.updateValueAndValidity();

      this.formVisita.get('codigoChamado')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoChamado')?.updateValueAndValidity();

      this.formVisita.get('codigoAcaoPreventiva')?.removeValidators([Validators.required]);
      this.formVisita.get('codigoAcaoPreventiva')?.updateValueAndValidity();

      this.formVisita.get('tipoGrupo')?.removeValidators([Validators.required]);
      this.formVisita.get('tipoGrupo')?.updateValueAndValidity();
    }
  }

  public ajusteValidacaoTodoForm() {
    if (this.formVisita) {
      this.formVisita.get('codigoPolo')?.addValidators([Validators.required]);
      this.formVisita.get('codigoPolo')?.updateValueAndValidity();

      this.formVisita.get('codigoDiretoria')?.addValidators([Validators.required]);
      this.formVisita.get('codigoDiretoria')?.updateValueAndValidity();
      this.formVisita.get('nomeDiretoria')?.addValidators([Validators.required]);
      this.formVisita.get('nomeDiretoria')?.updateValueAndValidity();

      this.formVisita.get('codigoEscola')?.addValidators([Validators.required]);
      this.formVisita.get('codigoEscola')?.updateValueAndValidity();
      this.formVisita.get('nomeEscola')?.addValidators([Validators.required]);
      this.formVisita.get('nomeEscola')?.updateValueAndValidity();

      this.formVisita.get('codigoPsicologo')?.addValidators([Validators.required]);
      this.formVisita.get('codigoPsicologo')?.updateValueAndValidity();

      this.formVisita.get('dataVisita')?.addValidators([Validators.required]);
      this.formVisita.get('dataVisita')?.updateValueAndValidity();

      this.formVisita.get('codigoAtividade')?.addValidators([Validators.required]);
      this.formVisita.get('codigoAtividade')?.updateValueAndValidity();

      this.formVisita.get('codigoChamado')?.addValidators([Validators.required]);
      this.formVisita.get('codigoChamado')?.updateValueAndValidity();

      this.formVisita.get('codigoAcaoPreventiva')?.addValidators([Validators.required]);
      this.formVisita.get('codigoAcaoPreventiva')?.updateValueAndValidity();

      this.formVisita.get('tipoGrupo')?.addValidators([Validators.required]);
      this.formVisita.get('tipoGrupo')?.updateValueAndValidity();

      this.formVisita.get('objetivoAcao')?.addValidators([Validators.required]);
      this.formVisita.get('objetivoAcao')?.updateValueAndValidity();

      this.formVisita.get('avaliacaoResultado')?.addValidators([Validators.required]);
      this.formVisita.get('avaliacaoResultado')?.updateValueAndValidity();

      this.formVisita.get('impactoAcao')?.addValidators([Validators.required]);
      this.formVisita.get('impactoAcao')?.updateValueAndValidity();

      this.formVisita.get('arquivos')?.removeValidators([Validators.required]);
      this.formVisita.get('arquivos')?.updateValueAndValidity();

    }
  }

  //#endregion

  public async caregarTurmas() {
    this.submitted = true;
    const codigoEs = this.formVisita.get('codigoEscola');

    if(codigoEs?.value != null) {
      this.spinnerService.show();

      const result = await lastValueFrom(await this.escolaService.getTurmasPorCodigoCie(codigoEs?.value, 0))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.lstTurmas = result.data;
      this.spinnerService.hide();

      this.showGridTurma = true;
      if(this.lstTurmas.length == 0){
        this.toastr.warning("Não existem Turmas para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
            this.hideAfterLoadTable = true;
          });
        }
      }
      else{
        this.hideAfterLoadTable = false;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);

          });
        } else {
          this.isDtInitialized = true;

          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
    }
  }

  public async onCheckTurma(codigoTurma: number, descricaoTurma: string){
    var existeEmLista: boolean = false;
    this.lstTurmasAtivas.forEach(element => {
      if(element == codigoTurma){
        existeEmLista = true;
      }
    });
    if(existeEmLista){
      const index = this.lstTurmasAtivas.indexOf(codigoTurma)
      this.lstTurmasAtivas.splice(index, 1);
      this.lstTurmasSelecionadas.splice(index, 1);
    }else{
        this.lstTurmasAtivas.push(codigoTurma);

        var turma = this.lstTurmas.find((fun) => fun.codigoTurma === codigoTurma);
        if(turma != null)
          this.lstTurmasSelecionadas.push(turma);

        const objetoEnvolvido: Envolvido = {
          tipoPessoa: 4,
          tipoEnvolvido: 0,
          nome: descricaoTurma,
          codigoTurma: codigoTurma,
          numeroClasse: turma?.numeroClasse,
          nomeTipoEnsino: turma?.nomeTipoEnsino,
          numeroSerie: turma?.numeroSerie,
          descricaoTurma: turma?.descricaoTurma
        };

        objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
        this.envolvidos.push(objetoEnvolvido);
        this.lstEnvolvidosSelecionados.push(objetoEnvolvido);
    }
  }

  public onCheckTodosFalse(){
    this.lstTurmaAluno.forEach(element => {
      element.presenca = false;
    });
  }

  public onCheckTodosTrue(){
    this.lstTurmaAluno.forEach(element => {
      element.presenca = true;
    });
  }

  public concatenaDataHora(data:string,hora:string): string{
    return `${data +" " + hora}`
  }

  public async OnFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      for (const file of files) {
        if(file.type == "image/jpg" ||
           file.type == "image/jpeg" ||
           file.type == "image/png" ||
           file.type ==  "application/pdf" ||
           file.type == "audio/mpeg" ||
           file.type == "video/mp4"){
          formData.append(file.name, file);
        }
        else{
          this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 e .mp4');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
      }
      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }else {
        this.files.push(...result.data);
        event.target.value = '';
      }
      this.spinnerService.hide();
    }
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
    const fileInput = document.getElementById('arquivos') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  public removeUniqueId(fileName: string): string {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
    return fileName.replace(regex, '');
  }

  public async getOcorrencias(){
    this.spinnerService.show();

    const codigoPsicologo = this.formVisita.get('codigoPsicologo')?.value;
    const codigoEscola = this.formVisita.get("codigoEscola")?.value;
    const psicologo = this.formVisita.get('codigoPsicologo');

    this.nomePsicologoSelecionado = this.lstPsicologos.find((fun) => fun.codigoPsicologo === psicologo?.value)?.nomePsicologo;


    const result = await lastValueFrom( this.chamadoService.getChamadosAgendados(codigoEscola, codigoPsicologo))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.lstOcorrenciasAgendadas = result.data;
    this.spinnerService.hide();
  }

  public async mostrarEnvolvidos(){
    this.OnChangeAtividade();
    var tipoEnvolvido = this.formVisita.get('codigoAtividade')?.value;
    this.OnLimparEnvolvidos();
    this.formVisita.get('tipoGrupo')?.patchValue(null);
    if(tipoEnvolvido == 1){
      await this.getEnvolvidosPorChamado();
      this.mostrarAlunos = true;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = false;
    }else if(tipoEnvolvido == 2){
      this.mostrarAlunos = false;
      this.mostrarGrupos = true;
      this.mostrarProffisionais = false;
    }else if(tipoEnvolvido == 3){
      this.mostrarAlunos = false;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = true;
    }else{
      this.mostrarAlunos = false;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = false;
    }
  }

  public async mostrarTipoGrupo(){
    var tipoGrupo = this.formVisita.get('tipoGrupo')?.value;
    this.OnLimparEnvolvidos();
    this.submittedModal = true;
    if(tipoGrupo == 1){
      this.mostrarDivTurmas = true;
      await this.caregarTurmas();
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = false;
    }else if(tipoGrupo == 2){
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = true;
      this.mostrarDivOutros = false;
    }else if(tipoGrupo == 3){
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = true;
    }else{
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = false;
    }
  }

  public async GetFuncionariosPorCpf(tela: number) {
    const cpf = tela == 1 ? this.formModalEquipeEscolar.get('cpfFuncionario')?.value : this.formModalControls.cpfBusca.value;
    if(!this.validarCPF(cpf)){
      this.toastr.warning('CPF invalido.');
      this.spinnerService.hide();
      return;
    }
    this.showDropAuxiliar = false;
    this.showDropFuncionarios = false;
    this.spinnerService.show();
    var getCPF = undefined;

    if(tela == 1){
      getCPF = this.formModalEquipeEscolar.get('cpfFuncionario');
    }
    else{
      getCPF = this.formModalControls.cpfBusca;
    }

    const result = await lastValueFrom(await this.funcionarioService.getFuncionariosPorCpf(getCPF?.value.replace(".","").replace("-","")))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    if (result.data != null) {
      this.lstFuncionarios = result.data;
    }

    if(tela == 2){this.showDropFuncionarios = true;}else{this.showDropAuxiliar = true;}
    if (this.lstFuncionarios.length == 0) {
      this.toastr.warning('Nenhum funcionario encontrado!');
    } else {
      if (this.lstFuncionarios.length == 1) {
        if(tela == 1){
          this.formModalEquipeEscolar.get('cpfFuncionarioModalDrop')?.patchValue(this.lstFuncionarios[0].cpf);
        }else{
          this.formModalControls.cpfProfissionalSelecionado.patchValue(this.lstFuncionarios[0].cpf);
        }

      }
    }

    this.spinnerService.hide();
  }

  public validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');
    if (cpf.length !== 11) {
      return false;
    }
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    return remainder === parseInt(cpf.substring(10, 11));
  }

  public FecharModalEquipeEscolar() {
    this.showModalEquipeEscolar = false;
  }

  public FecharModalOutros() {
    this.showModalOutros = false;
  }

  public AbrirModalEquipeEscolar() {
    this.submittedModal = false;
    this.formModalEquipeEscolar = new FormGroup({
      cpfFuncionario: new FormControl(null, [
        Validators.required,
        this.genericValidate.ValidaCpf,
      ]),
      cpfFuncionarioModalDrop: new FormControl(null, [Validators.required]),
    });
    this.showModalEquipeEscolar = true;
  }

  public AbrirModalOutros() {
    this.submittedModal = false;
    this.formModalOutros = new FormGroup({
      dsOutros: new FormControl(null, [Validators.required]),
    });
    this.showModalOutros = true;
  }

  public OnAdicionarEquipeEscolar(){
    if (this.formModalEquipeEscolar.invalid) {
      return;
    }

    const objetoEnvolvido: Envolvido = {
      tipoPessoa: 2,
      tipoEnvolvido: 0,
      cpf: this.formModalEquipeEscolar.get('cpfFuncionarioModalDrop')?.value,
    };

    const nomeFuncionario = this.lstFuncionarios.find((fun) => fun.cpf === objetoEnvolvido.cpf)?.nome
    const nomeFuncSplit = nomeFuncionario?.split(' ');

    if(nomeFuncSplit != undefined){
      const iniciais =  nomeFuncSplit
      .filter(parte => parte.length > 3)
      .map(parte => parte.charAt(0))
      .slice(0, 2)
      .join('');

      objetoEnvolvido.iniciais = iniciais;
    }

    if(nomeFuncSplit != null)
      objetoEnvolvido.nome = nomeFuncionario;

    objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
    this.envolvidos.push(objetoEnvolvido);
    this.lstEnvolvidosSelecionados.push(objetoEnvolvido);

    this.toastr.success(`Equipo Escolar inserido com sucesso`);
    this.FecharModalEquipeEscolar();
  }

  public OnAdicionarOutros(){
    const objetoEnvolvido: Envolvido = {
      tipoPessoa: 3,
      tipoEnvolvido: 0,
      dsOutros: this.formModalOutros.get('dsOutros')?.value,
      nome: this.formModalOutros.get('dsOutros')?.value,
    };

    const nomeFuncSplit = objetoEnvolvido.nome?.split(' ');

    if(nomeFuncSplit != undefined){
      const iniciais =  nomeFuncSplit
      .filter(parte => parte.length > 3)
      .map(parte => parte.charAt(0))
      .slice(0, 2)
      .join('');

      objetoEnvolvido.iniciais = iniciais;
    }

    objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
    this.envolvidos.push(objetoEnvolvido);
    this.lstEnvolvidosSelecionados.push(objetoEnvolvido);
    this.toastr.success(`Outros inserido com sucesso`);
    this.FecharModalOutros();
  }

  public OnExcluirEnvolvido(IdTemporario: number) {
    this.showModalExcluirEnvolvido = true;
    this.envolvidoExclusao = this.envolvidos.filter(
      (envolvido) => envolvido.idTemporario === IdTemporario
    )[0];
    this.descricaoExclusao = `Deseja remover o Envolvido ${this.envolvidoExclusao.nome == undefined  ? "Outros" : this.envolvidoExclusao.nome} ?`;
  }

  public FecharModalExclusao() {
    this.showModalExcluirEnvolvido = false;
    this.descricaoExclusao = "";
    this.envolvidoExclusao = undefined;
  }

  public ExluirEnvolvido(IdTemporario?: number) {
    const indiceParaRemover = this.envolvidos.findIndex(
      (envolvido) => envolvido.idTemporario === IdTemporario
    );
    this.envolvidos.splice(indiceParaRemover, 1);
    this.toastr.success(
      'Envolvido removido com sucesso!'
    );
    this.FecharModalExclusao();
  }

  public ValidaPassoAtual(passo:number):boolean{
    return this.Passo == passo;
  }

  public async getEnvolvidosPorChamado(){
    this.spinnerService.show();

    if(this.form.codigoChamado.value == null){
      this.toastr.error("Selecione um chamado");
      this.formVisita.get('codigoAtividade')?.patchValue(null);
      this.spinnerService.hide();
      return;
    }
    var response = await lastValueFrom(
      this.envolvidoService.GetEnvolvidosPorChamado(this.form.codigoChamado.value));

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.lstEnvolvidos = response.data;
    this.spinnerService.hide();

  }

  public addEnvolvidoOnLstEnvolvidoSelecionado(codigoEnvolvido:number,target:EventTarget){
    const checkbox = target as HTMLInputElement;
    if(checkbox.checked)
      this.lstEnvolvidosSelecionados.push(this.lstEnvolvidos.find(x=>x.codigoEnvolvido == codigoEnvolvido)!);
    else
      this.lstEnvolvidosSelecionados = this.lstEnvolvidosSelecionados.filter(x=>x.codigoEnvolvido != codigoEnvolvido);
  }

  public IsEnvolvidoSelected(envolvido:Envolvido):boolean{
    return this.lstEnvolvidosSelecionados.includes(envolvido);
  }

  public OnChangeData(){
    this.dataVisita =  this.formVisita.get('dataVisita')?.value;
  }

  public OnChangeOcorrencias(){
    var chamado = this.formVisita.get('codigoChamado');
    this.ocorrenciaAgendadaSelecionado = this.lstOcorrenciasAgendadas.find((fun) => fun.codigoChamado === chamado?.value)?.descricaoChamado;
  }

  public OnChangeAtividade(){
    var atividade = this.formVisita.get('codigoAtividade');
    this.atividadeRealizadaSelecionado = this.lstAtividadeRealizada.find((fun) => fun.id === atividade?.value)?.name;
  }

  public changeInputRange(){
    const inputRange =  document.getElementById('input-range') as HTMLFormElement;
    this.duracaoVisita = inputRange.value;
  }

  public OnAdicionarProfissionaisEOutros() {
    const tipoPessoa = this.formModalControls.tipoPessoa.value;
    const dsOutros = this.formModalControls.dsOutros.value;
    const cpfSelecionado = this.formModalControls.cpfProfissionalSelecionado.value;
    if (tipoPessoa == 1 && dsOutros) {
      this.lstOutros.push(dsOutros);
    } else if (tipoPessoa == 2 && cpfSelecionado) {
      var funcionario = this.lstFuncionarios.find(x => x.cpf == cpfSelecionado);
      if (funcionario) {
        this.lstFuncionariosSelecionados.push(funcionario);
      }
    } else {
      this.toastr.error('Preencha todos os campos.');
    }
    this.lstFuncionarios = [];
    this.FecharModal();
    this.formModalControls.tipoPessoa.setValue(null);
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);
  }

  public removeFuncionario(codigoFuncionario: number) {
    this.lstFuncionariosSelecionados = this.lstFuncionariosSelecionados.filter(x => x.codigoFuncionario != codigoFuncionario);
  }

  public removeOutro(outro: string) {
    this.lstOutros = this.lstOutros.filter(x => x != outro);
  }

  public AbreModalProfissionais() {
    this.showModal = true;
  }

  public FecharModal() {
    this.showModal = false;
    this.showDropFuncionarios = false;
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);
  }

  public changeTipoPessoaModal() {
    this.showDropFuncionarios = false;
    this.lstFuncionarios = [];
  }

  OnChangeCountLetters(campo: number){
    const avalia = this.formVisita.get('avaliacaoResultado')!.value;
    const impacto = this.formVisita.get('impactoAcao')!.value;
    if(avalia != null && campo == 1)
      this.qtdeCaracteresAvalia = avalia.length;
    if(impacto != null && campo == 2)
      this.qtdeCaracteresImpacto = impacto.length;
  }
}
