import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { FormControl, FormGroup, Validators } from "@angular/forms";

//Services
import { NgxSpinnerService } from "ngx-spinner";
import { EscolaService } from "src/app/shared/services/escola.service";
import { OAuthService } from "angular-oauth2-oidc";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { PerfilService } from "src/app/shared/services/perfil.service";

//Models
import { authConfig } from "src/app/shared/auth/models/auth.config";
import { DefaultResponse } from "src/app/shared/models/DefaultResponse";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Atribuicao, AtribuicaoLoginSed, AtribuicoesPorUsuario, PerfilLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { Perfil } from "src/app/shared/enums/Perfis/Perfis";
import { lastValueFrom } from "rxjs";
import { Comportamento } from "src/app/shared/enums/Perfis/Comportamento";

@Component({
  templateUrl: './gerenciar-login.component.html',
  styleUrls: ['./gerenciar-login.component.scss'],
})

export class GerenciarLoginComponent implements OnInit {
  private error: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private oauthService: OAuthService,
    private authService: AuthService,
    private perfilService: PerfilService,
    private spinnerService: NgxSpinnerService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private escolaService: EscolaService,
    private psicologoService: PsicologoService) {
    this.error = this.route.snapshot.queryParams.error;
    this.configure();
  }

  private configure() {
    this.oauthService.configure(authConfig);
  }


  // Models
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public usuario: UserLoginSed;
  public usuarioLogado: UserLoginSed;
  public resultPesquisa: boolean = false;

  // Forms
  public formModalEscola!: FormGroup;
  public formModalPsicologo!: FormGroup;
  public get form() { return this.formModalEscola.controls; }
  public get codigoDiretoria(){return this.formModalEscola.get('codigoDiretoria')!}
  public get codigoEscola(){return this.formModalEscola.get('codigoEscola')!}

  // Modal
  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  public showModal = false;
  public showModalEscola = false;
  public showModalPsicologo = false;

  public async ngOnInit() {
    this.spinnerService.show();

    this.formModalEscola = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required])
    });

    if(this.error != null && this.error != undefined && this.error != '') {
      this.toastr.error(this.error);
      this.spinnerService.hide();
      return;
    }

    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.authService.setTokenGovBr(this.oauthService.getAccessToken());
    this.authService.getTokenConviva().subscribe({
      next: async (response: DefaultResponse) => {
        if(response.isSucess) {
          const tokenSed = response.data.tokenConsumoConteudo;
          this.authService.setTokenConviva(tokenSed);
          await this.loginSed();
          return;
        }

        if(!response.isSucess){
          this.toastr.error(response.message)

          setTimeout(() => {
            this.router.navigate(['/login']);
            this.spinnerService.hide();
          }, 5000);
        }
      },
      error: (error) => {
        this.spinnerService.hide();
        this.ConvivaErrors.handleError(error);
      }
    });
  }

  public getDiretoriasPerfilEscola(atribuicoes: AtribuicaoLoginSed[]) {
    this.diretorias = [];
    this.escolas = [];

    atribuicoes?.forEach((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();
    }
    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
  }

  public async loginSed() {
      var response = await lastValueFrom(this.authService.LoginSed());

      if(response.statusCode == 401) {
        this.toastr.error('Este login não está autorizado a acessar o CONVIVA. Verifique um login correto.', 'CONVIVA');
        this.toastr.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');

        await this.oauthService.loadDiscoveryDocument();
        this.authService.cleanStorageGovBr();
        this.authService.cleanStorageConviva();
        this.oauthService.logOut();
      }

        this.usuario = UserLoginSed.MappingUsuarioConviva(response);

        if(response.statusCode == 200) {

          this.usuarioLogado = UserLoginSed.MappingUsuarioConviva(response);


           if(this.usuario.perfis != null && this.usuario.perfis.length > 0) {
            await this.getAtribuicoes();
          }else{
            this.toastr.error("Nenhum perfil encontrado para este usuário");
            setTimeout(()=>this.logout(),3000);
          }
        }


  }

  public async onClickPerfil(cdUsuario: number, codigoPerfil: number,codigoComportamento:number) {
    this.spinnerService.show();
    var response  =  await lastValueFrom(this.authService.getAtribuicoesPerfil(cdUsuario, codigoPerfil));

    if(response.statusCode == 401) {
      this.toastr.error('Este login não está autorizado a acessar o CONVIVA.', 'CONVIVA');
      this.toastr.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');
      this.spinnerService.hide();
      this.authService.cleanStorageGovBr();
      this.authService.cleanStorageConviva();
      await this.oauthService.loadDiscoveryDocument();
      this.oauthService.logOut();
    }

    if(response.statusCode == 200) {

      var perfilSelecionado = this.usuario.perfis.find((item:PerfilLoginSed)=> item.codigoPerfil == codigoPerfil)
      this.usuarioLogado.DadosUsuario[0].NumeroComportamento = perfilSelecionado!.codigoComportamento;
      this.usuarioLogado.DadosUsuario[0].NomePerfil = perfilSelecionado!.descricaoPerfil;
      this.usuarioLogado.DadosUsuario[0].CodigoPerfil = perfilSelecionado!.codigoPerfil;
      this.usuarioLogado.DadosUsuario[0].Comportamento = perfilSelecionado!.descricaoComportamento;
      this.usuarioLogado.nomePerfil = perfilSelecionado!.descricaoPerfil;
      this.usuarioLogado.codigoPerfil = perfilSelecionado!.codigoPerfil;
      this.usuarioLogado.NumeroComportamento = perfilSelecionado!.codigoComportamento.toString();
      this.usuarioLogado.cpf = String(this.usuarioLogado.DadosUsuario[0]!.Cpf);

      this.setDadosPerfilUnico(response.DadosUsuario);

      this.getDiretorias();
      await this.getFuncionalidades(codigoPerfil);
    }


    if(response.statusCode == 201){
      var perfilSelecionado = this.usuario.perfis.find((item:PerfilLoginSed)=> item.codigoPerfil == codigoPerfil)
      this.usuarioLogado.DadosUsuario[0].NumeroComportamento = perfilSelecionado!.codigoComportamento;
      this.usuarioLogado.DadosUsuario[0].NomePerfil = perfilSelecionado!.descricaoPerfil;
      this.usuarioLogado.DadosUsuario[0].CodigoPerfil = perfilSelecionado!.codigoPerfil;
      this.usuarioLogado.DadosUsuario[0].Comportamento = perfilSelecionado!.descricaoComportamento;
      this.usuarioLogado.nomePerfil = perfilSelecionado!.descricaoPerfil;
      this.usuarioLogado.codigoPerfil = perfilSelecionado!.codigoPerfil;
      this.usuarioLogado.NumeroComportamento = perfilSelecionado!.codigoComportamento.toString();
      this.usuarioLogado.cpf = String(this.usuarioLogado.DadosUsuario[0]!.Cpf);

      this.getDiretoriasCombo();
      this.getFuncionalidades(codigoPerfil);
    }
      // if(codigoPerfil == Perfil.Psicólogo)
      //     this.getLoginPsicologo();




  }

  public async getPerfil(cdUsuari: number, codigoPerfil: number,codigoComportamento:number){

    var response = await lastValueFrom(this.authService.getAtribuicoesPerfil(cdUsuari, codigoPerfil));

    if(response.statusCode == 200) {
      var perfil = this.usuario.perfis.find(item=>item.codigoPerfil == codigoPerfil)
      this.usuarioLogado.DadosUsuario[0].NumeroComportamento = perfil!.codigoComportamento;
      this.usuarioLogado.DadosUsuario[0].NomePerfil = perfil!.descricaoPerfil;
      this.usuarioLogado.DadosUsuario[0].CodigoPerfil = perfil!.codigoPerfil;
      this.usuarioLogado.DadosUsuario[0].Comportamento = perfil!.descricaoComportamento;
      this.usuarioLogado.nomePerfil = perfil!.descricaoPerfil;
      this.usuarioLogado.codigoPerfil = perfil!.codigoPerfil;
      this.usuarioLogado.cpf = String(this.usuarioLogado.DadosUsuario[0]!.Cpf);
      this.usuarioLogado.NumeroComportamento = perfil!.codigoComportamento.toString();
 
      this.setDadosPerfilUnico(response.DadosUsuario);
      this.getDiretorias();
      await this.getFuncionalidades(codigoPerfil);
    }

    if(response.statusCode == 401) {
      this.toastr.error('Este login não está autorizado a acessar o CONVIVA.', 'CONVIVA');
      this.toastr.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');

      this.spinnerService.hide();
      this.authService.cleanStorageGovBr();
      this.authService.cleanStorageConviva();
      await this.oauthService.loadDiscoveryDocument();
      this.oauthService.logOut();
    }

    if(response.statusCode == 201){

      var perfil = this.usuario.perfis.find(item=>item.codigoPerfil == codigoPerfil)

      this.usuarioLogado.DadosUsuario[0].NumeroComportamento = perfil!.codigoComportamento;
      this.usuarioLogado.DadosUsuario[0].NomePerfil = perfil!.descricaoPerfil;
      this.usuarioLogado.DadosUsuario[0].CodigoPerfil = perfil!.codigoPerfil;
      this.usuarioLogado.DadosUsuario[0].Comportamento = perfil!.descricaoComportamento;

      this.usuarioLogado.nomePerfil = perfil!.descricaoPerfil;
      this.usuarioLogado.codigoPerfil = perfil!.codigoPerfil;
      this.usuarioLogado.cpf = String(this.usuarioLogado.DadosUsuario[0]!.Cpf);

      this.getDiretoriasCombo();
      await this.getFuncionalidades(codigoPerfil);
    }

  }

  public async logout() {

    this.spinnerService.show();
    await this.oauthService.loadDiscoveryDocument();
    this.authService.cleanStorageGovBr();
    this.authService.cleanStorageConviva();
    if(this.oauthService.hasValidAccessToken()){
      this.spinnerService.hide();
      this.oauthService.logOut();
    }
    else {
      this.spinnerService.hide();
      this.router.navigate(['/logout']);
    }
  }

  public getDiretoriasCombo() {
    this.spinnerService.show();

    this.escolaService
      .getDiretoriaPorTipoDiretoria()
      .subscribe({
        next: (response: DefaultResponse) => {
          if(!response.isSucess){
            this.toastr.error(response.message);
          }
          this.diretorias = response.data;
          this.spinnerService.hide();
        },
        error: (error) => {
          this.ConvivaErrors.handleError(error);
          this.spinnerService.hide();
        },
    });
  }

  public async getDiretorias() {

    this.diretorias = [];
    this.escolas = [];
    this.usuarioLogado?.DadosUsuario?.forEach((item: AtribuicaoLoginSed) => {

      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      if(this.usuarioLogado?.codigoDiretoria != undefined && this.usuarioLogado.codigoEscola != undefined)
      {
        if(this.usuarioLogado?.codigoDiretoria == diretoria.codigoDiretoria)
          this.diretorias.push(diretoria);
      }
      else
        this.diretorias.push(diretoria);

        if(item.CodigoEscola && item.NomeEscola){
            let escola = new Escola();
            escola.codigoEscola = item.CodigoEscola;
            escola.nomeEscola = item.NomeEscola;
            escola.codigoDiretoria = item.CodigoDiretoria;
            if(this.usuarioLogado?.codigoDiretoria != undefined && this.usuarioLogado.codigoEscola != undefined)
            {
              if(this.usuarioLogado?.codigoDiretoria == diretoria.codigoDiretoria)
                this.escolas.push(escola);
            }
            else
              this.escolas.push(escola);
        }

    });



    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }

    if(this.diretorias.length == 1 && this.escolas.length == 0){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      await this.getEscolas();
    }

  }

  public getDiretoriasPerfilDiretoria() {

    this.diretorias = [];
    this.escolas = [];

    this.usuarioLogado?.DadosUsuario?.forEach((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

    }
  }

  public async getEscolas( ) {
    this.spinnerService.show();

    const codigoDE = this.formModalEscola.get('codigoDiretoria');
    if(this.usuarioLogado.DadosUsuario[0]?.NumeroComportamento == 1 ||
       this.usuarioLogado.DadosUsuario[0]?.NumeroComportamento == 2 ||
        this.usuarioLogado.DadosUsuario[0].CodigoPerfil == Perfil.Psicólogo){
      //this.zerarEscola();
      if (codigoDE?.value != null) {
        this.spinnerService.show();
        var response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(codigoDE?.value));
          if(!response.isSucess){
            this.toastr.error(response.message);
          }
          this.escolas = response.data;
          this.form.codigoEscola.enable();
          this.spinnerService.hide();
      }
    }
    this.spinnerService.hide();
  }

  public SelecionarEscola() {
    if (this.formModalEscola.invalid) {
      this.toastr.error('Favor de preencher a Diretoria e a Escola.', 'CONVIVA');
      return;
    }

    this.usuarioLogado.codigoDiretoria = this.formModalEscola.get('codigoDiretoria')?.value == null ? 0 : this.formModalEscola.get('codigoDiretoria')?.value;
    this.usuarioLogado.codigoEscola =  this.formModalEscola.get('codigoEscola')?.value == null ? 0 : this.formModalEscola.get('codigoEscola')?.value;
    this.authService.setUser(this.usuarioLogado);
    this.router.navigate(['/']);
  }

  public async FecharModalEscola()
  {
    this.spinnerService.show();
    await this.oauthService.loadDiscoveryDocument();
    this.authService.cleanStorageGovBr();
    this.authService.cleanStorageConviva();
    if(this.oauthService.hasValidAccessToken()){
      this.spinnerService.hide();
      this.oauthService.logOut();
    }
    else {
      this.spinnerService.hide();
      this.router.navigate(['/logout']);
    }
  }

  public async getLoginPsicologo() {

    var response = await lastValueFrom(this.psicologoService.getPsicologoPorCPF(this.usuario.cpf));

    if(!response.isSucess){
      this.toastr.error(response.message);

      setTimeout(async () => {
        await this.oauthService.loadDiscoveryDocument();
        this.authService.cleanStorageGovBr();
        this.authService.cleanStorageConviva();
        this.oauthService.logOut();
        this.spinnerService.hide();
      }, 3000);

      return;
    }

    if(response.data == null){
      this.toastr.error("Psicólogo sem acesso ao Conviva.");

      setTimeout(async () => {
        await this.oauthService.loadDiscoveryDocument();
        this.authService.cleanStorageGovBr();
        this.authService.cleanStorageConviva();
        this.oauthService.logOut();
        this.spinnerService.hide();
      }, 3000);

    }
    this.PopulaPsicologo(response.data);
    await this.getFuncionalidade(this.usuarioLogado.perfis[0].codigoPerfil);
    await this.getDiretoriasPorPolo(this.usuarioLogado.codigoPolo);
    this.MostraModalEscola();
    this.authService.setUser(this.usuarioLogado);
    this.spinnerService.hide();

  }

  private async getDiretoriasPorPolo(codigoPolo:number){
    var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(codigoPolo));
    if(!response.isSucess){
      this.toastr.error(response.message)

      setTimeout(() => {
        this.router.navigate(['/login']);
        this.spinnerService.hide();
      }, 5000);
    }

    this.diretorias = response.data;
    this.form.codigoDiretoria.enable();
    return;
  }

  private PopulaPsicologo(userData:any){

    this.usuarioLogado.flPsicologo = true;
    this.usuarioLogado.codigoPolo = userData.codigoPoloRegional;
    this.usuarioLogado.NumeroComportamento = '3';
    this.usuarioLogado.comportamento = 'Escola';
    this.usuarioLogado.nomePerfil = "Psicólogo";
    this.usuarioLogado.codigoPerfil = Perfil.Psicólogo;
    // this.usuarioLogado.cpf = userData.cpf;
    // this.usuarioLogado.nome = userData.nome;
    // this.usuarioLogado.login = userData.email;
    var atribuicao = this.usuarioLogado.DadosUsuario[0];
    atribuicao.CodigoPolo = userData.codigoPoloRegional;
    atribuicao.NumeroComportamento = 3;
    atribuicao.Comportamento = 'Escola';
    atribuicao.CodigoPerfil = Perfil.Psicólogo;
    this.usuarioLogado.DadosUsuario = [atribuicao];
  }

  public async getAtribuicoes() {
    var response  = await lastValueFrom(this.perfilService.getPerfisUsuario(this.usuario.perfis));

    this.usuario.perfis = response.data;

    if (this.usuario?.perfis?.length == 0) {
      this.toastr.error('Este login não está autorizado a acessar o CONVIVA. Não tem Perfis na SED.', 'CONVIVA');
      this.toastr.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');

      setTimeout(async () => {
          await this.oauthService.loadDiscoveryDocument();
          this.authService.cleanStorageGovBr();
          this.authService.cleanStorageConviva();
          this.oauthService.logOut();
          return;
      }, 6000);
    }

    if(this.usuario?.perfis?.length > 1){
      this.showModal = true;
      this.spinnerService.hide();
      return;
    }

    if(this.usuario?.perfis?.length == 1){

      if(this.usuario.perfis[0].codigoPerfil == Perfil.Psicólogo){
        await this.getLoginPsicologo();
      }else{
        this.getPerfil(this.usuario.DadosUsuario[0].CodigoUsuario, this.usuario.perfis[0].codigoPerfil,this.usuario.perfis[0].codigoComportamento)
      }

      return;

    }

    this.spinnerService.hide();
  }

  public async getFuncionalidades(codigoPerfil: number) {
    var response = await lastValueFrom(this.perfilService.getFuncionalidadesPerfil(codigoPerfil));

        this.usuarioLogado.funcionalidades = response.data;
        this.spinnerService.hide();
        this.showModal = false;
        this.showModalEscola = true;
        // if(this.usuarioLogado.DadosUsuario[0]?.NumeroComportamento == 1)
        //   this.getDiretoriasCombo();
        // if(this.usuarioLogado.DadosUsuario[0]?.NumeroComportamento == 2)
        //   this.getDiretoriasPerfilDiretoria();
        if(this.usuarioLogado.DadosUsuario[0]?.NumeroComportamento == 3 ||
          this.usuarioLogado.DadosUsuario[0]?.NumeroComportamento == 4 &&
          this.usuarioLogado.DadosUsuario[0]?.CodigoPerfil != Perfil.Psicólogo)
          this.getDiretoriasPerfilEscola(this.usuarioLogado.DadosUsuario);

  }

  public async getFuncionalidade(codigoPerfil: number){
    var response = await lastValueFrom(this.perfilService.getFuncionalidadesPerfil(codigoPerfil));
    if(!response.isSucess){
      this.toastr.error(response.message);
      return;
    }
    this.usuarioLogado.funcionalidades = response.data;

  }
  private MostraModalEscola(){
    this.showModal = false;
    this.showModalEscola = true;
  }

  public setDadosPerfilUnico(atribuicoes:Atribuicao[]){
    var novosDadosUsuarios:AtribuicaoLoginSed[] = [];
    var dadosUsuario = this.usuarioLogado.DadosUsuario[0];

    atribuicoes.forEach((item:Atribuicao)=>{
      let newAtribuicao = new AtribuicaoLoginSed();

      //Dados atribuições
      newAtribuicao.CodigoDiretoria = item.CodigoDiretoria;
      newAtribuicao.NomeDiretoria = item.NomeDiretoria;
      newAtribuicao.CodigoTurma = item.CodigoTurma;
      newAtribuicao.NomeEscola = item.NomeEscola;
      newAtribuicao.CodigoEscola = item.CodigoEscola;

      //Dados cabeçalho
      newAtribuicao.NumeroComportamento = dadosUsuario.NumeroComportamento;
      newAtribuicao.Comportamento = dadosUsuario.Comportamento;
      newAtribuicao.CodigoPerfil = dadosUsuario.CodigoPerfil;
      newAtribuicao.NomePerfil = dadosUsuario.NomePerfil;
      newAtribuicao.Cpf = dadosUsuario.Cpf;
      newAtribuicao.Email = dadosUsuario.Email;
      newAtribuicao.Login = dadosUsuario.Login;
      newAtribuicao.CodigoUsuario = dadosUsuario.CodigoUsuario;
      newAtribuicao.DataInclusao = dadosUsuario.DataInclusao;
      newAtribuicao.Nome = dadosUsuario.Nome;

      novosDadosUsuarios.push(newAtribuicao);
    });

    this.usuarioLogado.DadosUsuario = novosDadosUsuarios;
  }
}
