import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { HistoricoChamado } from "../models/chamados/historico";

@Injectable({
    providedIn: 'root'
  })

  export class ConcluirService {
    private api = ConvivaApi;

    constructor(private http: HttpClient)
    { }

    public setCadastrarConcluir(parametros: HistoricoChamado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Concluir.setCadastrarConcluir}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarConcluirChamadoEnvolvidos(parametros: HistoricoChamado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Concluir.setCadastrarConcluirChamadoEnvolvidos}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarConcluirChamadoPorAtividade(parametros: HistoricoChamado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Concluir.setCadastrarConcluirChamadoPorAtividade}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }
}
