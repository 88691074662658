
//Principais
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { lastValueFrom } from "rxjs";
import { Router } from '@angular/router';
//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AvaliacaoVisitaPsicologo } from "src/app/shared/models/psicologo/AvaliacaoVisitaPsicologo";
//Serviços
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";

@Component({
  selector: 'app-availacao-visita-fiscal',
  templateUrl: './availacao-visita-fiscal.component.html',
  styleUrls: ['./availacao-visita-fiscal.component.scss']
})
export class AvailacaoVisitaFiscalComponent implements OnInit{
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  public polos: Polo[] = [];
  public lstPsicologos: Psicologo[] = [];
  public lstAvaliacaoFiscal: AvaliacaoVisitaPsicologo[] =[];
  public showPesquisa: boolean;

  public titleExport = 'Avaliação de Visita Fiscal';
  public colunasGrid =  [
    { title: 'Código', field: 'codigoAtividadePsicologo', hozAlign: "center",headerHozAlign:"center" },
    { title: 'Nome Diretoria', field: 'nomeDiretoria', hozAlign: "center",headerHozAlign:"center" },
    { title: 'Nome Escola', field: 'nomeEscola', hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Escola Origem', field: 'deslocamentoEscolaOrigem', hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Nome Psicologo', field: 'nomePsicologo',width:250, hozAlign: "center" ,headerHozAlign:"center" },
    { title: 'Data Visita', field: 'dataVisitaString', width:150, hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Tipo Ação', field: 'descricaoTipoAcao', hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Tempo Ação Geral', field: 'tempoAcaoGeral', hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Ocorrência Agendada', field: 'ocorrenciaAgendada',width:250, hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Atividade Realizada', field: 'descricaoAtividadeRealizada', hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Validação Diretor', field: 'descricaoValidacaoDiretor', hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Envolvidos', field: 'envolvidos', hozAlign: "center",headerHozAlign:"center"  },
    {
      title: 'Visualizar', field: 'visualizar', hozAlign: "center", formatter: function (cell: any, formatterParams: any, onRendered: any) {
        return  `<div style='display:inline-flex;gap:0.4em;'> <i class='fa fa-eye'  aria-hidden='true'></i>`;
      },headerHozAlign:"center", cellClick:(e:any,cell:any)=> this.OnClickAcao(e,cell)
    }
    ];

  public formAvaliacao!: FormGroup;
  public get form() { return this.formAvaliacao.controls; }
  public submitted: boolean;

  constructor(
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    private psicologoService:PsicologoService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private router:Router
    )
    {
      this.userLogado = this.authService.getUser();
      if(this.userLogado != null){
        this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
      }
    }

  public async ngOnInit(): Promise<void> {
    this.formAvaliacao = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoPsicologo: new FormControl(null, [Validators.required])
    });
    await this.getPolos();
    await this.getPsicologos();
    await this.Visualizar();
  }

  public OnChangePolo() {
    const codigoPolo = this.formAvaliacao.get('codigoPolo')?.value;
    if (this.userLogado !== null) {
      this.userLogado.codigoPolo = codigoPolo == null ? 0 : codigoPolo;
      this.authService.setUser(this.userLogado);
    }
    if (codigoPolo != null) {
      this.getPsicologos();
    } else {
      this.zerarPsicologos();
    }
  }

  public zerarPsicologos() {
    this.lstPsicologos = [];
    this.formAvaliacao.get('codigoPsicologo')?.patchValue(null);
  }

  public async Visualizar(){
    this.submitted = true;
    this.spinnerService.show();

    if (this.formAvaliacao.invalid) {
      this.showPesquisa = false;
      this.spinnerService.hide();
      return;
    }
    const response = await lastValueFrom(this.psicologoService.getAvaliacaoFiscal(this.formAvaliacao.get('codigoPsicologo')!.value))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });
    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.showPesquisa = true;
    this.lstAvaliacaoFiscal = response.data;
  }

  public async getPolos() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos())
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.spinnerService.hide();
    if (this.userLogado?.codigoPolo != 0) {
      this.ativaPolo();
    }
    this.spinnerService.hide();
  }

  public ativaPolo() {
    if (this.userLogado?.codigoPolo != undefined)
      this.formAvaliacao.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
    if (this.userLogado !== null && this.userLogado)
      this.authService.setUser(this.userLogado);
  }

  public async onChangePsicologo() {
    const codigoPsicologo = this.formAvaliacao.get('codigoPsicologo')?.value;
    if (this.userLogado !== null && codigoPsicologo != null) {
      this.userLogado.codigoPsicologo = codigoPsicologo == null ? 0 : codigoPsicologo;
      this.authService.setUser(this.userLogado);
    }
  }

  public async getPsicologos() {
    if (this.userLogado?.codigoPolo) {
      this.zerarPsicologos();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.userLogado!.codigoPolo))
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.lstPsicologos = response.data;
      this.form.codigoPsicologo.enable();
      this.ativaPsicologo();
      this.spinnerService.hide();      
    }
    if (this.userLogado !== null) {
      this.userLogado.codigoPsicologo = this.userLogado.codigoPsicologo == null ? 0 : this.userLogado.codigoPsicologo;
      this.authService.setUser(this.userLogado);
    }
  }

  public ativaPsicologo() {
    const psicologo = this.lstPsicologos.filter(s => s.codigoPsicologo == this.userLogado?.codigoPsicologo);
    if (this.userLogado?.codigoPsicologo && this.userLogado?.codigoPsicologo > 0 && psicologo.length > 0) {
      this.formAvaliacao.get('codigoPsicologo')?.setValue(this.userLogado?.codigoPsicologo);
    }
    const polo = this.polos.filter(s => s.codigoPolo == this.userLogado?.codigoPolo);
    if (this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0 && polo.length > 0) {
      this.formAvaliacao.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
    }
  }

  private OnClickAcao(e:any, cell: any) {
    // Evento de click na célula para capturar os botões
    var row = cell.getRow().getData(); // Dados da linha
    var target = e.target; // Elemento clicado
    let codigoAtividadePsicologo = row.codigoAtividadePsicologo;
    if(target.classList.contains('fa-eye')){
      this.router.navigate(['/visualizar-atividade-psicologo/' + codigoAtividadePsicologo]);
    } 
  }

}
