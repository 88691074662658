<form [formGroup]="formNotificacoes" (submit)="PesquisarOcorrencias()">
  <!-- FILTRO ESCOLA -->
    <section class="section-filtro">
      <div class="container">
        <div class="grupo-filter">
          <p><b>Diretoria</b></p>
          <ng-select
            [items]="diretorias"
            bindLabel="nomeDiretoria"
            bindValue="codigoDiretoria"
            placeholder="Selecione uma Diretoria"
            formControlName="codigoDiretoria"
            name="codigoDiretoria"
            [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
            (change)="getEscolas()"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
            <div *ngIf="form.codigoDiretoria.errors.required">
              * Diretoria é obrigatório
            </div>
          </div>
        </div>

        <div class="grupo-filter">
          <p><b>Escola</b></p>
          <ng-select
            [items]="escolas"
            bindLabel="nomeEscola"
            bindValue="codigoEscola"
            placeholder="Selecione uma Escola"
            formControlName="codigoEscola"
            name="codigoEscola"
            (change)="getTurnos()"
            [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
            <div *ngIf="form.codigoEscola.errors.required">
              * Escola é obrigatório
            </div>
          </div>
        </div>

        <div class="grupo-filter">
          <p><b>Turno</b></p>
          <ng-select
            [items]="turnos"
            bindLabel="nomeTurno"
            bindValue="codigoTurno"
            placeholder="Selecione um Turno"
            formControlName="codigoTurno"
            name="codigoTurno"
            [ngClass]="{ 'is-invalid': submitted && form.codigoTurno.errors }"
            (change)="getTurmas()"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoTurno.errors" class="alert">
            <div *ngIf="form.codigoTurno.errors.required">
              * Turno é obrigatório
            </div>
          </div>
        </div>

        <div class="grupo-filter">
          <p><b>Turma</b></p>
          <ng-select
            [items]="turmas"
            bindLabel="descricaoTurma"
            bindValue="codigoTurma"
            placeholder="Selecione uma Turma"
            formControlName="codigoTurma"
            name="codigoTurma"
            [ngClass]="{ 'is-invalid': submitted && form.codigoTurma.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoTurma.errors" class="alert">
            <div *ngIf="form.codigoTurma.errors.required"></div>
          </div>
        </div>

        <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
          <img
            src="../../../assets/img/icons/icon-search.svg"
            alt="Pesquisar"
            title="Pesquisar"
          />
        </button>
      </div>
    </section>

  <!-- SAUDACAO USUÁRIO -->
  <section class="section-saudacao">
    <div class="container">
      <p>
        <b>Olá,</b> {{this.userLogado?.flPsicologo ? "Psicólogo(a)": this.atribuicoesLogin.NomePerfil }}<br />
        <b>{{this.userLogado?.flPsicologo ? this.userLogado?.nome : this.atribuicoesLogin.Nome}}</b>
      </p>
    </div>
  </section>

  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h2 class="titulo-page">Notificações</h2>
      <div class="periodo">
        <p><b>Pesquisar por período:</b></p>
        <div class="periodo-input">
          <input
          type="date"
          name="dataInicial"
          formControlName="dataInicio"
          />
          &nbsp;-&nbsp;
          <input
          type="date"
          name="dataFinal"
          formControlName="dataFim"
          />
        </div>
      </div>
    </div>
  </section>


  <section class="conteudo-principal">
    <div class="container-conviva">
      <div class="time-line">
        <h2>Todas as Notificações</h2>
        <div class="conteudo-notification">
          <div *ngFor="let item of this.lstNotificacoes; let i = index">
            <div class="card-notification">
              <input type="checkbox" checked class="card-input-notification"  id="card-notification-{{ i + 1 }}">
              <div class="perfil-aluno">
              <div class="img-aluno {{item.dsGravidade}}">
                <div *ngIf="item.foto" class="foto-perfil">
                  <img src="data:image/jpeg;base64,{{ item.foto }}"
                    alt=""
                  />
                </div>
                <div *ngIf="!item.foto" class="iniciais-perfil">
                  <span>{{ item.iniciaisNome }}</span>
                </div>
              </div>
            </div>
              <div class="info-card-notification">
                <div class="card-data">
                  <p>
                    <small>{{ item.horasAtras }}</small>
                  </p>
                </div>
                <div class="card-titulo">
                  <p>

                    {{item.dsTipoNotificacao + " "+ item.dsTipoAcao + " para "+ item.aluno?.nomeAluno }}
                  </p>
                </div>
                <div class="card-descricao-old">
                  <div class="card-descricao">
                    <p>
                      {{ item.descricaoChamado}}
                    </p>
                  </div>
                </div>
                <a
                  (click)="OnStatusChamado(item.codigoChamado, item.codigoEnvolvido)"
                  class="card-btn-notification">
                  Status do Chamado
                </a>
              </div>

              <div class="btn-acoes">
                <label for="card-notification-{{ i + 1 }}">
                  <a class="btn-view-card"></a>
                </label>
                <input type="checkbox" checked class="card-opcoes-card"  id="card-opcoes-card-{{ i + 1 }}">
                <label for="card-opcoes-card-{{ i + 1 }}">
                  <a class="btn-opcoes-card"></a>
                </label>
                <div class="opcoes-card caixa-opcoes">
                  <a *ngIf="this.userLogado?.funcionalidades?.includes(4)" href="javascript:void(0)"
                    (click)="OnExcluirChamado(item.codigoChamado)">
                    Excluir
                  </a>
                  <a *ngIf="(
                  (item.aluno?.statusEnvolvido == 0)
                      && (this.userLogado?.funcionalidades?.includes(6) || this.userLogado?.funcionalidades?.includes(7) || this.userLogado?.funcionalidades?.includes(8)))
                  || ((item.aluno?.statusEnvolvido == 5 || item.aluno?.statusEnvolvido == 6 ) && this.userLogado?.funcionalidades?.includes(16))"
                  href="javascript:void(0)" (click)="AbrirModal(item.codigoChamado, item.codigoEnvolvido)"  href="javascript:void(0)"
                  (click)="AbrirModal(item.codigoChamado, item.codigoEnvolvido)">Encaminhamento</a>

                  <a href="javascript:void(0)"
                  (click)="OnVisualizarChamado(item.codigoChamado)"
                  >Visualizar Chamado</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!-- time-line -->

      <aside class="sidebar">

        <div class="links-acesso-rapido">
          <h3>Links rápidos</h3>
          <h5>acesso</h5>
          <ul>
            <li *ngIf="this.userLogado?.funcionalidades?.includes(2)">
              <a href="javascript:void(0)" routerLink="/cadastrar-chamados" title="Adicionar Chamado">
                Adicionar Chamado
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/buscar-alunos">Buscar Estudantes com Ocorrências</a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/listar-chamados">Estudantes com Ocorrências Registradas</a>
            </li>
          </ul>
        </div>

        <div class="sidebar-alunos-recorrentes">
          <h3>Estudantes  Recorrentes</h3>
          <div class="alunos-recorrentes" *ngIf="this.submitted && this.showPesquisa">
            <div *ngFor="let item of this.alunosRecorrentes; let i = index">
              <a
                href="javascript:void(0)"
                [routerLink]="['/perfil-aluno/', item.ra]"
                title="Perfil Aluno"
                class="alunos"
              >
                <div *ngIf="item.foto" class="img-aluno {{item.dsGravidade}}">
                  <img src="data:image/jpeg;base64,{{item.foto}}" alt="Imagem do Aluno">
                </div>
                <div *ngIf="!item.foto" class="img-aluno {{item.dsGravidade}}">
                  <span>{{ item.iniciais }}</span>
                </div>
                <div class="nome-aluno">
                  {{ item.nomeAluno }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </aside><!-- sidebar -->
    </div>
  </section>

</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModal">
  <form [formGroup]="formModal">
    <div>
      <div>
        <h1 class="titulo-page">{{ this.tituloModal }}</h1>
      </div>
      <br />

      <div>
        <div class="container-btns container-btns-centrar grupo-filter">
          <div *ngIf="this.userLogado?.funcionalidades?.includes(6)">
            <button
              type="button"
              (click)="EncaminhamentoPsicologo()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Psicólogo
            </button>
          </div>
          <br>
          <div *ngIf="this.userLogado?.funcionalidades?.includes(7)">
            <button
              type="button"
              (click)="EncaminhamentoPolicia()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-police.svg" alt="" />
              &nbsp; Rede Protetiva
            </button>
          </div>
          <br>
          <div *ngIf="this.userLogado?.funcionalidades?.includes(8)">
            <button
              type="button"
              (click)="EncaminhamentoSaude()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Rede Hospitalar
            </button>
          </div>
          <br>
          <div>
            <button type="button" (click)="FecharModal()" class="btn-action">
              &nbsp; Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>

<app-modal *ngIf="showModalExcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>{{this.descricaoExclusao}}</span>
    </div>
    <div class="container-btns">
      <input type="button" (click)="FecharModalExclusao()" class="btn_outline" value="Não"/>
      <input type="button" (click)="ExluirChamado(this.chamadoARemover)" class="btn_primary" value="Sim" />
    </div>
  </div>
</app-modal>
