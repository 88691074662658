<form [formGroup]="formVisita">

  <!-- Filtros -->
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <label for="select-categoria">Polo</label>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="cambioPolo()"
          [ngClass]="{
            'is-invalid': submitted && form.codigoPolo.errors
          }"
          (change)="cambioPolo()"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoPolo || "Unnamed group" }}
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="trocaDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Titulo -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Visita Ocasional Psicólogo</h2>
    </div>
  </section>

  <!-- Barra de Progresso -->
  <step-bar [passoAtual]="Passo" [temDeslocamento]="this.temDeslocamento" (outPasso)="setNovoPasso($event)"></step-bar>

  <!-- Form -->
  <section class="container-conviva form-registro">
    @if(this.temDeslocamento) {

      @if(ValidaPassoAtual(1)) {
        <!-- Deslocamento -->
        <app-deslocamento [dadosDeslocamento]="this.modelDeslocamento" (proximoPasso)="proximoPassoDeslocamento($event)"></app-deslocamento>
      }

      @if(ValidaPassoAtual(2)) {
          <!-- Psicologos -->
          <div formGroupName="PassoUm" class="container form-vista-psicologo info-psicologo">

            <div class="conteudo-select">
              <label for="select-gravidade">Psicólogo: </label>
              <ng-select
                [items]="lstPsicologos"
                bindLabel="nomePsicologo"
                bindValue="codigoPsicologo"
                placeholder="Selecione um Psicólogo"
                name="nomePsicologo"
                formControlName="codigoPsicologo"
                (change)="OnChangePsicologo()"
                [ngClass]="{'is-invalid': submitted && formPassoUm.codigoPsicologo.errors}"
              >
              </ng-select>
              <div *ngIf=" submitted && formPassoUm.codigoPsicologo.errors" class="alert">
                <div *ngIf="formPassoUm.codigoPsicologo.errors.required">
                  * Psicólogo obrigatório
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label for="select-data-visita">Data da Visita: </label>
              <input type="date" name="dataVisita" class="input-date" formControlName="dataVisita" />
              <div *ngIf="submitted && formPassoUm.dataVisita.errors" class="alert">
                <div *ngIf="formPassoUm.dataVisita.errors.required">* Data obrigatória</div>
              </div>
            </div>

            <div class="conteudo-range-slider">
              <label>Quanto tempo durou a ação?</label>
              <input-range (change)="changeInputRange()"/>
            </div>

            <!-- <div class="conteudo-select"> -->
              <!-- <label for="select-gravidade">Ocorrências agendadas:</label>
              <ng-select
                [items]="lstOcorrenciasAgendadas"
                bindLabel="descricaoChamado"
                bindValue="codigoChamado"
                placeholder="Selecione uma Ocorrência"
                name="codigoChamado"
                formControlName="codigoChamado"
                (change)="OnChangeOcorrencias()"
                [ngClass]="{'is-invalid': submitted && form.codigoChamado.errors}"
              >
              </ng-select>
              <div *ngIf="submitted && form.codigoChamado.errors" class="alert">
                <div *ngIf="form.codigoChamado.errors.required">
                  * Chamado é obrigatório
                </div>
              </div>
            </div> -->

            <!-- <div class="conteudo-select">
              <label for="select-gravidade">Qual atividade foi realizada?</label>
              <ng-select
                [items]="lstAtividadeRealizada"
                bindLabel="name"
                bindValue="id"
                placeholder="Selecione o tipo de acolhimento"
                name="codigoAtividade"
                formControlName="codigoAtividade"
                (change)="mostrarEnvolvidos()"
                [ngClass]="{'is-invalid': submitted && form.codigoAtividade.errors}"
              >
              </ng-select>
              <div *ngIf="submitted && form.codigoAtividade.errors" class="alert">
                <div *ngIf="form.codigoAtividade.errors.required">
                  * Atividade Realizada é obrigatório
                </div>
              </div>
            </div> -->



            <div class="container-btns-visitas">
              <input
                type="reset"
                class="btn_outline"
                id="btnLimpar"
                name="b2"
                value="Limpar"
                (click)="OnLimpar()"
              />

              <input
                type="button"
                (click)="setVoltarPasso(2)"
                class="btn_outline"
                value="Voltar"
              />

              <input
                type="button"
                class="btn_primary"
                value="Próximo"
                (click)="setNovoPasso(3)"
              />
            </div>
          </div>
      }

      @if(ValidaPassoAtual(3)) {
        <!-- Ações -->
        <div formGroupName="PassoDois" class="container form-vista-psicologo acoes-psicologo">


          <div class="grupo-filter-full">
            <label for="descricaoAcao">Qual ação foi realizada?</label>
            <textarea
              id="descricaoAcao"
              name="descricaoAcao"
              rows="6"
              cols="40"
              minlength="150"
              maxlength="500"
              (keyup)="OnChangeCountLetters(1)"
              formControlName="descricaoAcao"
            ></textarea>
            <p>Quantidade de caracteres atual: {{qtdeCaracteresDescricaoAcao}}</p>
            <div *ngIf="submitted && formPassoDois.descricaoAcao.errors" class="alert">
              <div *ngIf="formPassoDois.descricaoAcao.errors?.required">
                * Avaliação da ação é obrigatória.
              </div>
              <div *ngIf="formPassoDois.descricaoAcao.errors?.minlength">
                * É obrigatório no mínimo digitar 150 caracteres.
              </div>
              <div *ngIf="formPassoDois.descricaoAcao.errors?.maxlength">
                * Maximo de caracteres é 500.
              </div>
            </div>
          </div>


          <div class="conteudo-upload grupo-filter-full">
            <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
            <div class="input-upload">
              <input
                type="file"
                id="arquivos"
                name="arquivos"
                accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4"
                (change)="OnFileChange($event)"
                multiple
              />
              <label for="arquivos" class="message-container" *ngIf="files.length === 0">
                Nenhum arquivo escolhido
              </label>
              <label for="arquivos" class="message-container" *ngIf="files.length > 0">
                Selecionar mais arquivos
              </label>
            </div>

            <div *ngIf="files.length > 0" class="imagem-escolhida">
              <div *ngFor="let file of files; let i = index">
                <div class="imagem-item">
                  <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                  <div class="info-container">
                    <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                    <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                    <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                    <span>{{ removeUniqueId(file.nome).length > 50 ? removeUniqueId(file.nome).slice(0, 50) + '...' : removeUniqueId(file.nome) }}</span>
                    <i class="fas fa-times" (click)="removeFile(i)"></i>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="submitted && files.length == 0" class="alert">
              <div >
                * Arquivos obrigatórios
              </div>
            </div>

          </div>

          <div class="container-btns-visitas">
            <input
              type="reset"
              class="btn_outline"
              id="btnLimpar"
              name="b2"
              value="Limpar"
              (click)="OnLimpar()"
            />

            <input
              type="button"
              (click)="setVoltarPasso(3)"
              class="btn_outline"
              value="Voltar"
            />

            <input
              type="button"
              (click)="setNovoPasso(4)"
              class="btn_primary"
              value="Próximo"
            />
          </div>
        </div>
      }

      @if(ValidaPassoAtual(4)) {

        <!-- Visualização Geral -->
        <div class="container form-vista-psicologo visualizacao-geral">

          <div class="grupo-filter-full">
            <label>Psicólogo:</label>
            <p>{{this.psicologoSelecionado.nomePsicologo}}</p>
          </div>

          <div class="conteudo-row">
            <div class="box-duas-colum">
              <div class="grupo-filter-full">
                <label>Data da Visita: </label>
                <p>{{getDate(FormValues.PassoUm.dataVisita)}}</p>
              </div>
            </div>
            <div class="box-duas-colum">
              <div class="grupo-filter-full">
                <label>Quanto tempo durou a ação?</label>
                <p>{{duracaoVisita}}MN</p>
              </div>
            </div>
          </div>



          <div class="grupo-filter-full">
            <label>Qual ação realizada?</label>
            <p>{{this.FormValues.PassoDois.descricaoAcao}}</p>
          </div>

          <h3 class="sub-titulo-dash">Deslocamento:</h3>
          <div class="conteudo-row">
            <div class="box-duas-colum">
              <div class="grupo-filter-full">
                <label>Origem:</label>
                <p>Diretoria:{{modelDeslocamento?.nomeDiretoriaOrigem}} </p>
                <p>Escola: {{modelDeslocamento?.nomeEscolaOrigem}}</p>
                <p>Horário saída: {{modelDeslocamento?.horarioSaida}}</p>
              </div>
            </div>
            <div class="box-duas-colum">
              <div class="grupo-filter-full">
                <label>Destino: </label>
                <p>Diretoria: {{modelDeslocamento?.nomeDiretoriaDestino}}</p>
                <p>Escola: {{modelDeslocamento?.nomeEscolaDestino}}</p>
                <p>Horário chegada: {{modelDeslocamento?.horarioChegada}}</p>
                <p><b>Tempo de Deslocamento: {{modelDeslocamento?.tempoDeslocamento}}</b></p>
              </div>
            </div>
          </div>

          <div class="grupo-filter-full">
            <label>Fotos ou vídeos</label>
            <div *ngIf="files.length > 0" class="imagem-escolhida">
              <a href="javascript:void(0)" *ngFor="let arquivo of files" class="icon-arquivo">
                {{arquivo.nome.substring(37,arquivo.nome.length+1)}}
              </a>
            </div>
          </div>

          <div class="container-btns-visitas">
            <input
              type="reset"
              class="btn_outline"
              id="btnLimpar"
              name="b2"
              value="Cancelar"
              (click)="OnLimpar()"
            />

            <input
              type="button"
              (click)="setVoltarPasso(3)"
              class="btn_outline"
              value="Voltar"
            />

            <input
              type="button"
              (click)="OnSalvar()"
              class="btn_primary"
              value="Enviar para aprovação"
              [disabled]="enableEnviarAprovacao"
            />
          </div>
        </div>
      }
    }
    @else {
      @if(ValidaPassoAtual(1)) {
        <!-- Psicologos -->
        <div formGroupName="PassoUm" class="container form-vista-psicologo info-psicologo">
          <div class="conteudo-select">
            <label for="select-gravidade">Psicólogo: </label>
            <ng-select
              [items]="lstPsicologos"
              bindLabel="nomePsicologo"
              bindValue="codigoPsicologo"
              placeholder="Selecione um Psicólogo"
              name="nomePsicologo"
              formControlName="codigoPsicologo"
              [ngClass]="{'is-invalid': submitted && formPassoUm.codigoPsicologo.errors}"
              (change)="OnChangePsicologo()"
            >
            </ng-select>
            <div *ngIf="submitted && formPassoUm.codigoPsicologo.errors" class="alert">
              <div *ngIf="formPassoUm.codigoPsicologo.errors.required">
                * Psicólogo obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter-full">
            <label for="select-data-visita">Data da Visita: </label>
            <input type="date" name="dataVisita" class="input-date" formControlName="dataVisita" />
            <div *ngIf="submitted && formPassoUm.dataVisita.errors" class="alert">
              <div *ngIf="formPassoUm.dataVisita.errors.required">* Data obrigatória</div>
            </div>
          </div>

          <div class="conteudo-range-slider">
            <label>Quanto tempo durou a ação?</label>
            <input-range (change)="changeInputRange()"/>
          </div>

          <div class="container-btns-visitas">
            <input
              type="reset"
              class="btn_outline"
              id="btnLimpar"
              name="b2"
              value="Limpar"
              (click)="OnLimpar()"
            />
            <input
              type="button"
              (click)="setNovoPasso(2)"
              class="btn_primary"
              value="Próximo"
            />
          </div>
        </div>
      }

      @if(ValidaPassoAtual(2)) {
        <!-- Ações -->
        <div  formGroupName="PassoDois" class="container form-vista-psicologo acoes-psicologo">

          <div class="grupo-filter-full">
            <label for="descricaoAcao">Qual ação foi realizada?</label>
            <textarea
              id="descricaoAcao"
              name="descricaoAcao"
              rows="6"
              cols="40"
              minlength="150"
              maxlength="500"
              (keyup)="OnChangeCountLetters(1)"
              formControlName="descricaoAcao"
            ></textarea>
            <p>Quantidade de caracteres atual: {{qtdeCaracteresDescricaoAcao}}</p>
            <div *ngIf="submitted && formPassoDois.descricaoAcao.errors" class="alert">
              <div *ngIf="formPassoDois.descricaoAcao.errors?.required">
                * Avaliação da ação é obrigatória.
              </div>
              <div *ngIf="formPassoDois.descricaoAcao.errors?.minlength">
                * É obrigatório no mínimo digitar 150 caracteres.
              </div>
              <div *ngIf="formPassoDois.descricaoAcao.errors?.maxlength">
                * Maximo de caracteres é 500.
              </div>
            </div>
          </div>


          <div class="conteudo-upload grupo-filter-full">
            <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
            <div class="input-upload">
              <input
                type="file"
                id="arquivos"
                name="arquivos"
                accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4"
                (change)="OnFileChange($event)"
                multiple
              />
              <label for="arquivos" class="message-container" *ngIf="files.length === 0">
                Nenhum arquivo escolhido
              </label>
              <label for="arquivos" class="message-container" *ngIf="files.length > 0">
                Selecionar mais arquivos
              </label>
            </div>

            <div *ngIf="files.length > 0" class="imagem-escolhida">
              <div *ngFor="let file of files; let i = index">
                <div class="imagem-item">
                  <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                  <div class="info-container">
                    <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                    <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                    <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                    <span>{{ removeUniqueId(file.nome).length > 50 ? removeUniqueId(file.nome).slice(0, 50) + '...' : removeUniqueId(file.nome) }}</span>
                    <i class="fas fa-times" (click)="removeFile(i)"></i>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="submitted && files.length == 0" class="alert">
              <div >
                * Arquivos obrigatórios
              </div>
            </div>

          </div>

          <div class="container-btns-visitas">
            <input
              type="reset"
              class="btn_outline"
              id="btnLimpar"
              name="b2"
              value="Limpar"
              (click)="OnLimpar()"
            />

            <input
              type="button"
              (click)="setVoltarPasso(2)"
              class="btn_outline"
              value="Voltar"
            />

            <input
              type="button"
              (click)="setNovoPasso(3)"
              class="btn_primary"
              value="Próximo"
            />
          </div>
        </div>
      }

      @if(ValidaPassoAtual(3)) {

        <!-- Visualização Geral -->
        <div class="container form-vista-psicologo visualizacao-geral">

          <div class="grupo-filter-full">
            <label>Psicólogo:</label>
            <p>{{this.psicologoSelecionado.nomePsicologo}}</p>
          </div>

          <div class="conteudo-row">
            <div class="box-duas-colum">
              <div class="grupo-filter-full">
                <label>Data da Visita: </label>
                <p>{{getDate(FormValues.PassoUm.dataVisita)}}</p>
              </div>
            </div>
            <div class="box-duas-colum">
              <div class="grupo-filter-full">
                <label>Quanto tempo durou a ação?</label>
                <p>{{duracaoVisita}}MN</p>
              </div>
            </div>
          </div>



          <div class="grupo-filter-full">
            <label>Qual ação realizada?</label>
            <p>{{this.FormValues.PassoDois.descricaoAcao}}</p>
          </div>

          <div class="grupo-filter-full">
            <label>Fotos ou vídeos</label>
            <div *ngIf="files.length > 0" class="imagem-escolhida">
              <a href="javascript:void(0)" *ngFor="let arquivo of files" class="icon-arquivo">
                {{arquivo.nome.substring(37,arquivo.nome.length+1)}}
              </a>
            </div>
          </div>

          <div class="container-btns-visitas">
            <input
              type="reset"
              class="btn_outline"
              id="btnLimpar"
              name="b2"
              value="Cancelar"
              (click)="OnLimpar()"
            />

            <input
              type="button"
              (click)="setVoltarPasso(3)"
              class="btn_outline"
              value="Voltar"
            />

            <input
              type="button"
              (click)="OnSalvar()"
              class="btn_primary"
              value="Enviar para aprovação"
              [disabled]="enableEnviarAprovacao"
            />
          </div>
        </div>
      }
    }
  </section>
<!-- </form> -->



<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
