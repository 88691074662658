import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}

  exportToExcel(data: any[], fileName: string, sheetName: string, tipoRelatorio: number): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    switch (tipoRelatorio){
      case 1://Arquivo Consolidado
        XLSX.utils.sheet_add_aoa(worksheet, [["DIRETORIA DE ENSINO", "ESCOLA", "Nº DE OCORRÊNCIAS", "Nº DE NÃO OCORRÊNCIAS", "Nº DE ENVOLVIDOS", "Nº DE ATENDIMENTOS PSICÓLOGOS", "Nº DE CHAMADOS ENCAMINHADOS PARA PSICÓLOGOS", "% DE OCORRÊNCIAS ENC. P/ ATENDIMENTO PSICOLÓGICO", "Nº DE CHAMADOS ENCAMINHADOS PARA REDE PROTETIVA", "% DE OCORRÊNCIAS ENC. P/ REDE PROTETIVA", "% DE OCORRÊNCIAS COM ATENDIMENTO PSICOLÓGICO REALIZADO", "% DE PREENCHIMENTO DO SISTEMA (OCORRÊNCIA + NÃO OCORRÊNCIA)"]], { origin: "A1" });
        break;
      case 2://Arquivo Pendencias
        XLSX.utils.sheet_add_aoa(worksheet, [["DIRETORIA DE ENSINO", "ESCOLA", "Nº DE OCORRÊNCIAS", "Nº DE NÃO OCORRÊNCIAS", "% DE PENDÊNCIA DE PREENCHIMENTO DO SISTEMA (NÚMERO TOTAL DE DIAS MENOS OS DIAS REGISTRADOS COM OCORRÊNCIA OU NÃO OCORRÊNCIA)", "PENDÊNCIAS DE PREENCHIMENTO"]], { origin: "A1" });
        break;
      case 3://Arquivo Visitas Psicologos
        worksheet["!cols"] = [ { wch: 25 }, { wch: 30 }, { wch: 25 }, { wch: 25 }, { wch: 35 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }]
        XLSX.utils.sheet_add_aoa(worksheet, [["DIRETORIA DE ENSINO", "ESCOLA", "DATA/HORA INICIO", "DATA/HORA FIM", "TURMA", "ESTUDANTE ENVOLVIDO NA AÇÃO", "DESCRIÇÃO DA AÇÃO","AÇÕES PROPOSITIVAS/DESENVOLVIMENTO", "ATIVIDADES DESENVOLVIDAS", "FOI IDENTIFICADO ALGUM ESTUDANTE COM NECESSIDADE DE ATENDIMENTO CLINICO?", "ENCAMINHADO PARA REDE DE SAUDE " ]], { origin: "A1",cellStyles:true });
        break;
      case 4://Arquivo Chamados por Usuario
        XLSX.utils.sheet_add_aoa(worksheet, [["CD DIRETORIA", "DIRETORIA DE ENSINO", "CD ESCOLA", "ESCOLA", "CPF USUARIO", "NOME USUARIO", "CD PERFIL", "NOME PERFIL", "QUANTIDADE CHAMADOS"]], { origin: "A1" });
        break;
      case 5://Arquivo Ocorrencia Ativas
            XLSX.utils.sheet_add_aoa(worksheet, [["CD DIRETORIA", "DIRETORIA DE ENSINO", "CD ESCOLA", "ESCOLA", "CD CHAMADO", "DATA CHAMADO", "CATEGORIA", "SUBCATEGORIA", "CD USUARIO", "NOME USUARIO", "CD PERFIL", "NOME PERFIL", "ATIVO/INATIVO", "AGRESSORES", "VITIMAS"]], { origin: "A1" });
        break;
      case 6://Arquivo Calculo Usabilidade
        XLSX.utils.sheet_add_aoa(worksheet, [["CD DIRETORIA", "DIRETORIA DE ENSINO", "PORCENTAGEM PENDENCIA"]], { origin: "A1" });
        break;
      default:
        break;
    }

    const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }
}
