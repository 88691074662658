<form [formGroup]="formVisita" (submit)="OnSalvar()">
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <label for="select-categoria">Polo</label>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="cambioPolo()"
          [ngClass]="{
            'is-invalid': submitted && form.codigoPolo.errors
          }"
          (change)="cambioPolo()"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoPolo || "Unnamed group" }}
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="trocaDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Visita Psicólogos</h2>
    </div>
  </section>

  <section class="conteudo-principal">
    <div class="container-conviva form-registro">
      <!-- INFO DO CHAMADO -->
      <div class="form-visita-psicologos conteudo-registro">
        <!-- Titulo -->
        <div class="conteudo-select">
          <label for="select-gravidade">Titulo: </label>
          <input
            type="text"
            id="text-titulo"
            name="text-titulo"
            formControlName="tituloVisita"
            name="tituloVisita"
          />
          <div *ngIf="submitted && form.tituloVisita.errors" class="alert">
            <div *ngIf="form.tituloVisita.errors.required">
              * Título obrigatório
            </div>
          </div>
        </div>
        <!-- Psicologo -->
        <div class="conteudo-select">
          <label for="select-gravidade">Psicólogo: </label>
          <ng-select
            [items]="lstPsicologos"
            bindLabel="nomePsicologo"
            bindValue="codigoPsicologo"
            placeholder="Selecione um Psicólogo"
            name="nomePsicologo"
            formControlName="codigoPsicologo"
            *ngIf="NomePsicologo == undefined"
            [ngClass]="{
              'is-invalid': submitted && form.codigoPsicologo.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoPsicologo.errors" class="alert">
            <div *ngIf="form.codigoPsicologo.errors.required">
              * Psicólogo obrigatório
            </div>
          </div>
        </div>

        <div class="section-filtro section-filtro-horario">
          <div class="container">
            <div class="grupo-filter">
              <label for="select-data-visita">Data da Visita: </label>
              <input
                type="date"
                name="dataVisita"
                class="input-date"
                formControlName="dataVisita"
              />
              <div *ngIf="submitted && form.dataVisita.errors" class="alert">
                <div *ngIf="form.dataVisita.errors.required">
                  * Data obrigatória
                </div>
              </div>
            </div>
            <div class="grupo-filter">
              <label for="select-hora-inicio">Hora Inicio: </label>
              <input
              class="input_style"
              type="time"
              formControlName="horaInicio">
              <div *ngIf="submitted && form.horaInicio.errors" class="alert">
                <div *ngIf="form.horaInicio.errors.required">
                  * Hora Inicio obrigatória
                </div>
              </div>
            </div>
            <div class="grupo-filter">
              <label for="select-hora-fim">Hora Fim: </label>
              <input
              class="input_style"
              type="time"
              formControlName="horaFim">
              <div *ngIf="submitted && form.horaFim.errors" class="alert">
                <div *ngIf="form.horaFim.errors.required">
                  * Hora Fim obrigatória
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="conteudo-select">
          <label for="select-gravidade">Atividade Desenvolvida: </label>
          <ng-select
            [items]="atividadesDesenvolvidas"
            bindLabel="name"
            bindValue="id"
            placeholder="Selecione uma Atividade"
            formControlName="codigoAtividade"
            name="codigoAtividade"
            [ngClass]="{ 'is-invalid': submitted && form.codigoAtividade.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoAtividade.errors" class="alert">
            <div *ngIf="form.codigoAtividade.errors.required">
              * Atividade é obrigatória
            </div>
          </div>
        </div>
        <div class="conteudo-select">
          <label for="select-gravidade">Ação Propositiva / Desenvolvimento: </label>
          <ng-select
            [items]="acoesPreventiva"
            bindLabel="name"
            bindValue="id"
            placeholder="Selecione uma Ação"
            formControlName="codigoAcaoPreventiva"
            name="codigoAcaoPreventiva"
            [ngClass]="{ 'is-invalid': submitted && form.codigoAcaoPreventiva.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoAcaoPreventiva.errors" class="alert">
            <div *ngIf="form.codigoAcaoPreventiva.errors.required">
              * Ação Propositiva é obrigatória
            </div>
          </div>
        </div>
        <div class="conteudo-select">
          <label for="text-descricao">Descrição da visita</label>
          <textarea
            id="text-descricao"
            name="text-descricao"
            rows="6"
            cols="50"
            formControlName="dsVisita"
            name="dsVisita"
          ></textarea>
          <div *ngIf="submitted && form.dsVisita.errors" class="alert">
            <div *ngIf="form.dsVisita.errors.required">
              * Descrição obrigatória
            </div>
          </div>
        </div>

        <div class="conteudo-upload">
          <div class="conteudo-textarea">
            <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
          </div> 
          <div class="input-upload">
            <input
              type="file"
              id="arquivos"
              name="arquivos"
              accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4"
              (change)="OnFileChange($event)"
              multiple
            />
            <label for="arquivos" class="message-container" *ngIf="files.length === 0">
              Nenhum arquivo escolhido
            </label>
            <label for="arquivos" class="message-container" *ngIf="files.length > 0">
              Selecionar mais arquivos
            </label>
          </div>

          <div *ngIf="files.length > 0" class="imagem-escolhida">
            <div *ngFor="let file of files; let i = index">
              <div class="imagem-item">
                <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                <div class="info-container">
                  <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                  <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                  <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                  <span>{{ removeUniqueId(file.nome).length > 50 ? removeUniqueId(file.nome).slice(0, 50) + '...' : removeUniqueId(file.nome) }}</span>
                  <i class="fas fa-times" (click)="removeFile(i)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-btns">
          <input
            type="reset"
            class="btn_outline"
            id="btnLimpar"
            name="b2"
            value="Limpar"
            (click)="OnLimpar()"
          />
          <input
            type="button"
            (click)="caregarTurmas()"
            class="btn_primary"
            value="Próximo"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="conteudo-principal">
    <div class="container">
      <div
        class="conteudo-perfil"
        *ngIf="this.submitted && this.showGridTurma"
        [hidden]="hideAfterLoadTable"
      >
        <h3 class="sub-titulo-dash">Turmas</h3>
        <div class="grid-datatable">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border"
            aria-describedby="dashboard"
          >
            <thead>
              <tr>
                <th>Codigo Turma</th>
                <th>Numero Classe</th>
                <th>Tipo de Ensino</th>
                <th>Numero de Serie</th>
                <th>Descrição Turma</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstTurmas">
                <td>{{ item.codigoTurma }}</td>
                <td>{{ item.numeroClasse }}</td>
                <td>{{ item.nomeTipoEnsino }}</td>
                <td>{{ item.numeroSerie }}</td>
                <td>{{ item.descricaoTurma }}</td>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      class="checkbox"
                      (change)="onCheckTurma(item.codigoTurma)"
                    /><i></i>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

  <form [formGroup]="formVisitaAlunos">
    <section class="conteudo-principal">
      <div class="container">
        <div
          class="conteudo-perfil"
          *ngIf="this.submitted && this.showGridAlunos"
          [hidden]="hideAfterLoadTableAlunos"
        >
        <div class="container conteudo-titulo-grid-alunos">
          <h3 class="sub-titulo-dash">Estudantes</h3>

          <div class="marcacao_falta_presenca">
            <p>Marcar todos como:</p>
            <button title="Falta" Class="btn_falta_presenca falta " (click)="onCheckTodosFalse()">
              <span> F </span>
            </button>
            <button title="Presença" Class="btn_falta_presenca presenca" (click)="onCheckTodosTrue()">
              <span> C </span>
            </button>
          </div>
        </div>
          <div class="grid-datatable">
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border"
              aria-describedby="dashboard"
            >
              <thead>
                <tr>
                  <th>Turma</th>
                  <th>N°</th>
                  <th>RA</th>
                  <th>Nome do Aluno</th>
                  <th>Presença / Falta</th>
                  <th>Atendimento Clinico</th>
                  <th>Encaminhamento Para Rede De Saude ou Assistencia Social</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let aluno of lstTurmaAluno">
                  <td>{{ aluno.descricaoTurma }}</td>
                  <td>&nbsp; {{ aluno.numeroAluno }} &nbsp;</td>
                  <td>{{ aluno.raAluno }}</td>
                  <td>{{ aluno.nomeAluno }}</td>
                  <td>
                    <div class="falta_presenca_container">
                      <input
                        [(ngModel)]="aluno.presenca"
                        type="checkbox"
                        title=""
                        formControlName="presenca"
                      />
                    </div>
                  </td>
                  <td>
                    <label>
                      <input
                        [(ngModel)]="aluno.atendimentoClinico"
                        type="checkbox"
                        class="checkbox"
                        formControlName="atendimentoClinico"
                      /><i></i>
                    </label>
                  </td>
                  <td>
                    <label class="selecione-encaminhamento">
                      <ng-select
                      [items]="encaminhamentos"
                      [(ngModel)]="aluno.encaminhamentoRedeSaude"
                      bindLabel="name"
                      bindValue="id"
                      placeholder="Selecione uma Encaminhamento"
                      formControlName="encaminhamentoRedeSaude"
                      name="encaminhamentoRedeSaude"
                      >
                      </ng-select>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </form>

  <section class="conteudo-principal">
    <div class="container-conviva form-registro" style=" max-width: 1255px;">
      <div
        class="form-visita-psicologos conteudo-registro"
        style="background-color: transparent;"
        *ngIf="this.submitted && this.showGridAlunos"
        [hidden]="hideAfterLoadTableAlunos"
      >
        <div class="container-btns">
          <input
            type="reset"
            class="btn_outline"
            id="btnLimpar"
            name="b2"
            value="Cancelar"
            (click)="OnLimpar()"
          />
          <input type="submit" class="btn_primary" value="Salvar" />
        </div>
      </div>
    </div>
  </section>

</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>


