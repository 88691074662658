
  <!-- Filtro -->
   <app-filtro-padrao (filtroEmitter)="listeningFiltroEmitter($event)"></app-filtro-padrao>


      <!-- SAUDACAO USUÁRIO -->
  <section class="section-saudacao">
    <div class="container">
      <p>
        <b>Olá,</b> {{this.userLogado?.flPsicologo ? "Psicólogo(a)": this.atribuicoesLogin.NomePerfil }}<br />
        <b>{{this.userLogado?.flPsicologo ? this.userLogado?.nome : this.atribuicoesLogin.Nome}}</b>
      </p>
    </div>
  </section>

  <!-- CALENDARIO -->
  @if(this.userLogado?.funcionalidades?.includes(18)) {
    <section class="section-calendario">
      <div class="container">

        <div class="header-calendario">
          <button (click)="goToPreviousMonth()" class="icone voltar-mes ">Mês Anterior</button>
          <h2>{{ currentDate | date: 'MMMM yyyy':" ":"pt" }}</h2>
          <button (click)="goToCurrentMonth()" class="icone avancar-mes ">Mês Atual</button>
        </div>

        <div class="dias">
          <div *ngFor="let dia of listaDias"
              class="dia"
              [ngClass]="{ 'indisponivel': dia.estatusDia == 5, 'dia-sem-ocorrencia': dia.estatusDia == 2, 'dia-com-ocorrencia': dia.estatusDia == 3, 'dia-sem-marcacao': dia.estatusDia == 4, 'dia-atual': dia.estatusDia == 1 }"
              (click)="OnInserirOcorrenciaNaoOcorrencia(dia.data, dia.estatusDia)"
              >

            <p class="dia-semana">{{ dia.diaDaSemana }}</p>
            <p class="numero">{{ dia.data| date: 'dd' }}</p>
          </div>
        </div>

        <div class="container legenda-calendario">
          <p><b>Legenda: </b> Calendário</p>
          <div class="conteudo-legenda">

            <div class="box-legenda dia-atual">
              <i class="icone-dot"></i> <p>Dia Atual</p>
            </div>
            <div class="box-legenda dia-sem-ocorrencia">
              <i class="icone-dot"></i> <p>Dia sem ocorrência</p>
            </div>
            <div class="box-legenda dia-com-ocorrencia">
              <i class="icone-dot"></i> <p>dia com ocorrência</p>
            </div>
            <div class="box-legenda dia-sem-marcacao">
              <i class="icone-dot"></i> <p>dia sem marcação</p>
            </div>
            <div class="box-legenda indisponivel">
              <i class="icone-dot"></i> <p>indisponível</p>
            </div>

          </div>
        </div>

      </div>
    </section>
  }

  <!-- TIME LINE E ACESSOS RAPIDOS -->
  <section class="conteudo-principal">
    <div class="container-conviva">

      <!-- TIME LINE -->
      <div class="time-line">

        <div class="filtro-time-line">
          <label for="filtro-gravidade">Filtros</label>

          <input type="number" placeholder="Numero da Ocorrência" class="filtro-ocorrencia" (input)="FiltrarChamadoId($event)"/>

          <ng-select
            [items]="gravidades"
            bindLabel="name"
            bindValue="id"
            class="select-gravidade"
            placeholder="Selecione uma Gravidade"
            (change)="FiltraChamadosAtuais($event)"
            name="codigoGravidade"
            >
            <!-- formControlName="codigoGravidade" -->
            <ng-option *ngFor="let grv of gravidades" [value]="grv.id">
              {{ grv.name }}
            </ng-option>
          </ng-select>

        </div>

        <h2>O que tem de novo</h2>

        @if(this.submitted && this.showPesquisa) {
          @for(item of this.lstChamados | slice: (page-1) * 5 : page * 5; track item; let i = $index) {
            <div>
              <article class="card-timeline">

                <input type="checkbox" checked class="card-feed" id="card-feed-{{ i + 1 }}"/>

                <div class="header-card">

                  <div class="perfil-aluno">
                    <div class="img-aluno {{item.dsGravidade}}">
                      <div *ngIf="item.foto" class="foto-perfil">
                        <img
                          src="data:image/jpeg;base64,{{ item.foto }}"
                          alt=""
                        />
                      </div>
                      <div *ngIf="!item.foto" class="iniciais-perfil">
                        <span>{{ item.iniciaisNome }}</span>
                      </div>
                    </div>

                    <div class="info-aluno">
                      <p class="numero-chamado">Chamado {{ item.codigoChamado }}</p>

                      <p>{{ item.aluno?.nomeAluno }}</p>
                      <p class="descricao-chamado {{getCorStatusEnvolvido(item.aluno?.statusEnvolvido)}}">
                        {{ item.dsEnvolvido + " - " + item.nomeSubCategoria + " - " + item.aluno?.dsStatusEnvolvido }}
                      </p>
                      <p>
                        <small>{{ item.horasAtras }}</small>
                      </p>
                      <p>
                        <small>{{ item.dataChamado | date : 'dd/MM/yyyy'}}</small>
                      </p>
                    </div>
                  </div>

                  <app-tres-pontos
                    [Chamado]="item"
                    [Index]="i"
                    [TemEnvolvido]="true"
                    (ItemEvent)="EventTresPontos($event)">
                  </app-tres-pontos>
                </div>

                <div class="imagem-destaque-card">
                  <div class="foto-perfil" style="position: relative;">

                    <img [src]="item.imagemChamado[0]" alt="" *ngIf="item.imagemChamado.length > 0 && !item.imagemChamado[0].endsWith('.pdf')" />
                    <i class="pdf" *ngIf="item.imagemChamado.length > 0 && item.imagemChamado[0].endsWith('.pdf')"></i>
                    <div *ngIf="item.imagemChamado.length > 1" [ngClass]="{'mensagem-arquivos':!item.imagemChamado[0].endsWith('.pdf'),'mensagem-arquivo-pdf':item.imagemChamado[0].endsWith('.pdf')}">
                      <a>Este chamado possui {{ item.imagemChamado.length }} arquivos anexados</a>
                    </div>
                  </div>
                </div>

                <div class="descricao-card">
                  <p>{{ item.descricaoChamado }}</p>
                </div>

                <div class="footer-card">
                  <div class="historico">
                    <a href="javascript:void(0)" (click)="OnVisualizarChamado(item.codigoChamado)"> Visualizar Chamado</a>
                  </div>
                  <div class="encaminhamento">
                    <a *ngIf="((item.aluno?.statusEnvolvido == 0)
                    && (this.userLogado?.funcionalidades?.includes(6) || this.userLogado?.funcionalidades?.includes(7) || this.userLogado?.funcionalidades?.includes(8)))
                    || ((item.aluno?.statusEnvolvido == 5 || item.aluno?.statusEnvolvido == 6) && this.userLogado?.funcionalidades?.includes(16))"
                    href="javascript:void(0)" (click)="AbrirModal(item.codigoChamado, item.codigoEnvolvido)">
                    Encaminhamento</a>
                  </div>
                </div>

              </article>
              <br />
            </div>
          }

          <ngb-pagination
            [(page)]="page"
            [pageSize]="5"
            [maxSize]="5"
            [collectionSize]="this.lstChamados.length"
            class="d-flex justify-content-center"
            size="lg" />
        }
      </div>

      <!-- MENU RAPIDO -->
      <aside class="sidebar">

        <!-- LINKS RAPIDOS -->
        <div class="links-acesso-rapido">
          <h3>Links rápidos</h3>
          <h5>acesso</h5>
          <ul>
            <li *ngIf="this.userLogado?.funcionalidades?.includes(2)">
              <a href="javascript:void(0)" routerLink="/cadastrar-chamados" title="Adicionar Chamado">
                Adicionar Chamado
              </a>
            </li>
            <li *ngIf=" this.userLogado?.funcionalidades?.includes(16)">
              <a href="javascript:void(0)" routerLink="/notificacoes" title="Notificações"> Notificações </a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/buscar-alunos" title="Buscar Estudantes com Ocorrências"> Buscar Estudantes com Ocorrências </a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/listar-chamados" title="Tela de Estudantes com Ocorrências Registradas"> Estudantes com Ocorrências Registradas </a>
            </li>
          </ul>
        </div>

        <!-- ALUNOS RECORRENTES -->
        <div class="sidebar-alunos-recorrentes">
          <h3>Estudantes  Recorrentes</h3>

          <div class="alunos-recorrentes" *ngIf="this.submitted && this.showPesquisa">
            <div *ngFor="let item of this.alunosRecorrentes; let i = index">
              <a
                href="javascript:void(0)"
                [routerLink]="['/perfil-aluno/', item.ra]"
                title="Perfil Aluno"
                class="alunos"
              >

              <div *ngIf="item.foto" class="img-aluno {{item.dsGravidade}}">
                <img src="data:image/jpeg;base64,{{item.foto}}" alt="Imagem do Aluno">
              </div>
              <div *ngIf="!item.foto" class="img-aluno {{item.dsGravidade}}">
                <span>{{ item.iniciais }}</span>
              </div>
              <div class="nome-aluno">
                {{ item.nomeAluno }}
              </div>

              </a>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </section>



<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModal">
  <form [formGroup]="formModal">
    <div>
      <div>
        <h1 class="titulo-page">{{ this.tituloModal }}</h1>
      </div>
      <br />

      <div>
        <div class="container-btns container-btns-centrar grupo-filter">
          <div *ngIf="this.userLogado?.funcionalidades?.includes(6)">
            <button
              type="button"
              (click)="EncaminhamentoPsicologo()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Psicólogo
            </button>
          </div>
          <br>
          <div *ngIf="this.userLogado?.funcionalidades?.includes(7)">
            <button
              type="button"
              (click)="EncaminhamentoPolicia()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-police.svg" alt="" />
              &nbsp; Rede Protetiva
            </button>
          </div>
          <br>
          <div *ngIf="this.userLogado?.funcionalidades?.includes(8)">
            <button
              type="button"
              (click)="EncaminhamentoSaude()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Rede Hospitalar
            </button>
          </div>
          <br>
          <div>
            <button type="button" (click)="FecharModal()" class="btn-action">
              &nbsp; Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>

<app-modal *ngIf="showModalCalendarioOcorrencia" class="modal-calendario">
  <div class="container-conviva form-registro modal-envolvidos">
    <div class="header-modal-calendario">
      <h1 class="titulo-page">Deseja Inserir Ocorrencia?</h1>
      <a class="btn-fechar-modal" (click)="FecharModalCalendario()" >fechar</a>
    </div>
    <div class="container-btns">
      <input type="button" (click)="InserirOcorrencia()" class="btn_primary" value="Sim"/>
      <input type="button" (click)="InserirNaoOcorrencia()" class="btn_outline" value="Não" />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalCadastraNaoOcorrencia" class="modal-ata-calendario">
  <form [formGroup]="formHomeNaoOcorrencia">
    <div class="container-conviva form-registro modal-envolvidos">
      <div class="header-modal-ata">
        <h1 class="titulo-page">Não Ocorrencia</h1>
        <a class="btn-fechar-modal" (click)="FecharModalCadastroNaoOcorrencia()" >fechar</a>
      </div>
      <div class="descricao-modal-excluir">
        <span><strong>Dia: </strong>{{this.dataOcorrenciaNaoOcorrencia | date : 'dd-MM-yyyy'}}</span>
      </div>

      <div class="conteudo-textarea">
        <label for="text-registro">Faça uma breve descrição de como foi o dia escolar.</label>
        <textarea
          id="text-registro"
          name="text-registro"
          rows="6"
          cols="50"
          formControlName="dsChamado"
          name="dsChamado"
          minlength="50"
          (keyup)="OnChangeCountLetters()"
          [ngClass]="{ 'is-invalid': submitted && formNaoOco.dsChamado.errors }"
        ></textarea>
        <p class="legenda-textarea">Quantidade de caracteres atual: {{qtdeCaracteresDescricao}}</p>
      </div>

      <div class="container-btns">
        <input type="button" (click)="InserirRegistroNaoOcorrencia()" class="btn_primary" value="Salvar" />
        <input type="button" (click)="FecharModalCadastroNaoOcorrencia()" class="btn_outline" value="Cancelar"/>
      </div>
    </div>

  </form>
</app-modal>
