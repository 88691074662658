import { formatDate } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { ListarEncaminhados } from "src/app/shared/models/chamados/listar-encaminhados";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Polo } from "src/app/shared/models/polo/Polo";
import { Agendamentos } from "src/app/shared/models/psicologo/Agendamento";
import { CriacaoAgendamento } from "src/app/shared/models/psicologo/CriacaoAgedamento";
import { ExclusaoAgendamento } from "src/app/shared/models/psicologo/ExclusaoAgendamento";
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { CategoriaService } from "src/app/shared/services/categoria.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { Categoria } from 'src/app/shared/models/categoria/Categoria';
import { Perfil } from "src/app/shared/enums/Perfis/Perfis";
import { CadAtendimento } from "src/app/shared/models/psicologo/CadAtendimento";
import { AtendimentoService } from "src/app/shared/services/atendimento.service";
import { AgendamentoService } from "src/app/shared/services/agendamento.service";

@Component({
  selector: 'agendamento-psicologos',
  templateUrl: './agendamento-psicologos.component.html',
  styleUrls: ['./agendamento-psicologos.component.scss']
})
export class AgendamentoPsicologosComponent implements OnInit, OnDestroy {

  private get DataAtual() {return new Date()}
  public minDate:string = '2021-01-01' ;
  public maxDate:string = `${new Date().getFullYear()}-12-30` ;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public showModalAtendimentoPsicologo =  false;
  public descricaoAtendimento = "";
  public chamadoAAtender = 0;
  public envolvidoAAtender = 0;
  public showDataAtendimento:boolean;
  public formAtendimento!: FormGroup;
  public quantidadeAgendamentosAbertos: number;
  public DesabilitaBotaoExcluirAgendamento: boolean;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private psicologoService:PsicologoService,
    private categoriaService: CategoriaService,
    private atendimento: AtendimentoService,
    @Inject(LOCALE_ID) private locale: string,
    private agendamentoService:AgendamentoService,
    private router:Router
  ){
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  // Listas da tela
  public gravidades = [ { id: 1, name: 'Amarelo' }, { id: 2, name: 'Vermelho' }];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public lstChamados: Agendamentos[] = [];
  public polos: Polo[] = [];
  public lstPsicologos: Psicologo[] = [];
  public categorias: Categoria[] = [];
  public agendamentoAtual?:Agendamentos;

  //Grid
  public titleExport = 'Listagem de Chamados Agendados';
  public colunasGrid =  [
    { title: 'Nº Chamado', field: 'codigoChamado', width:80, hozAlign: "center",headerHozAlign:"center" },
    { title: 'Data Abertura', field: 'dataAberturaString', width:100, hozAlign: "center",headerHozAlign:"center"},
    { title: 'Codigo Envolvido', field: 'codigoEnvolvido', visible:false },
    { title: 'Nome Envolvido', field: 'nomeEnvolvido', width:140, hozAlign: "center",headerHozAlign:"center", formatter:"textarea" },
    { title: 'Tipo Envolvido', field: 'tipoEnvolvimento', width:100, hozAlign: "center",headerHozAlign:"center"  },
    { title: 'Ocorrência/Status', field: 'ocorrenciaStatus', width:120, hozAlign: "center" ,headerHozAlign:"center", formatter:"textarea" },
    { title: 'Polo/Diretoria', field: 'poloDiretoria', width:140, hozAlign: "center",headerHozAlign:"center", formatter:"textarea" },
    { title: 'Escola', field: 'escola', width:150, hozAlign: "center",headerHozAlign:"center", formatter:"textarea" },
    { title: 'H/D Atendimento', field: 'dataAtendimentoString', width:100, hozAlign: "center",headerHozAlign:"center", formatter:"textarea" },
    { title: 'Psicologo', field: 'psicologo',width:140, hozAlign: "center",headerHozAlign:"center", formatter:"textarea" },
    { title: 'Visualizar', field: 'visualizar', hozAlign: "center", width:45, formatter: function (cell: any, formatterParams: any, onRendered: any) {
        return  `<div style='display:inline-flex;gap:0.4em;'> <i class='fa fa-eye'  aria-hidden='true'></i>`;
    },headerHozAlign:"center", cellClick:(e:any,cell:any)=> this.OnClickAcaoVisualizar(e,cell) },
    { title: 'Agendamento', field: 'visualizar', hozAlign: "center", width:40, formatter: function (cell: any, formatterParams: any, onRendered: any) {
      return  `<div style='display:inline-flex;gap:0.4em;'> <i class="fa-solid fa-calendar-days"></i>`;
    },headerHozAlign:"center", cellClick:(e:any,cell:any)=> this.OnClickAcaoAgendamento(e,cell) },
    { title: '', field: 'visualizar', hozAlign: "center", width:40, formatter: function (cell: any, formatterParams: any, onRendered: any) {
      return `<div style='display:inline-flex;gap:0.4em;'> <i class="fa-solid fa-clipboard-user"></i>`;
    },headerHozAlign:"center", cellClick:(e:any,cell:any)=> this.OnClickAcaoAtendimento(e,cell) }
    
    ];

  //Form
  public formDashboard!: FormGroup;
  public formAgendamento!: FormGroup;
  public get form() { return this.formDashboard.controls; }
  public get formAg() { return this.formAgendamento.controls; }
  public submitted: boolean;
  public submittedModal: boolean;
  public showPesquisa: boolean;
  public hideAfterLoadTable: boolean;


  //Modal
  public showModalAgendamento:boolean;
  public showModalConfirmacao:boolean;
  public DataAtendimento?:Date;
  public HoraAtendimento?:Date;
  public NomePsicologo?:string;

  public async ngOnInit(): Promise<void> {

    this.getCategorias();

    //Formulario de pesquisa.
    this.formDashboard = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null),
      codigoEscola: new FormControl(null),
      codigoSubCategoria: new FormControl(null),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null),
    });

    this.formAgendamento = new FormGroup({
      psicologo: new FormControl(null,[Validators.required]),
      dataAgendada: new FormControl(null,[Validators.required]),
      horarioAgendado: new FormControl("00:00",[Validators.required])
    });

    this.formAtendimento = new FormGroup({
      dataAtendimento: new FormControl(null,[Validators.required])
    });

    if(!this.userLogado?.flPsicologo && this.atribuicoesLogin.CodigoUsuario == 10000008457961){
      this.atribuicoesLogin.NumeroComportamento = 1;
    }

    if(this.userLogado?.flPsicologo){
      await this.getPoloDiretoriaPsicologo();
    }
    else{
        if( this.atribuicoesLogin.NumeroComportamento == 1){
          await this.getPolosAdmin();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 2){
         await this.getPolosPerfilDiretor();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
         await this.getPolosPerfilProfessor();
        }
    }

  }

  private OnClickAcaoVisualizar(e:any, cell: any) {
    // Evento de click na célula para capturar os botões
    var row = cell.getRow().getData(); // Dados da linha
    var target = e.target; // Elemento clicado
    let codigoChamado = row.codigoChamado;
    if(target.classList.contains('fa-eye')){
      this.router.navigate(['/detalhe/' + codigoChamado]);
    } 
  }

  private OnClickAcaoAgendamento(e:any, cell: any) {
    // Evento de click na célula para capturar os botões
    var row = cell.getRow().getData(); // Dados da linha
    var target = e.target; // Elemento clicado
    let codigoChamado = row.codigoChamado;
    let codigoEnvolvido = row.codigoEnvolvido;
    if(target.classList.contains('fa-calendar-days')){
      this.AbreModalAgendamento(codigoChamado,codigoEnvolvido);
    }
  }

  private OnClickAcaoAtendimento(e:any, cell: any) {
    // Evento de click na célula para capturar os botões
    var row = cell.getRow().getData(); // Dados da linha
    var target = e.target; // Elemento clicado
    let codigoChamado = row.codigoChamado;
    let codigoEnvolvido = row.codigoEnvolvido;
    let psicologo = row.psicologo;
    if(target.classList.contains('fa-clipboard-user') && psicologo){
      this.AbreModalAtendimento(codigoChamado,codigoEnvolvido);
    }else{
      this.toastr.warning("Este chamado não possui Psicólogo agendado.");
    }
  }

  public async PesquisarChamados() {

    this.spinnerService.show();
    this.submitted = true;
    if(this.formDashboard.invalid){
      this.showPesquisa = false;
      this.spinnerService.hide();
      return;
    }

    this.hideAfterLoadTable = false;
    const parametros = this.SetParametrosChamados();
    var response = await lastValueFrom(this.psicologoService.getEncaminhamentos(parametros)).catch((err) => {
      this.spinnerService.hide();
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
    }
    this.spinnerService.hide();

    this.lstChamados =  await this.OrdenaListaColocandoValorNuloNoTopoEEmOrdemAlfabeticaOutrosItens(response.data);

    if(!this.userLogado?.flPsicologo){
     await this.getPsicologosPolo();
    }else{
      this.setUnicoPsicologo();
    }

    this.showPesquisa = true;
    await this.buscarAgendamentosEmAberto();
  }

  public SetParametrosChamados(): ListarEncaminhados {
    const chamado = new ListarEncaminhados();
    chamado.codigoPolo = this.form?.codigoPolo.value;
    chamado.codigoDiretoria = this.form?.codigoDiretoria.value;
    chamado.codigoEscola = this.form?.codigoEscola.value;
    if(this.form?.codigoSubCategoria.value == null)
      chamado.codigoSubCategoria  = 0;
    else
      chamado.codigoSubCategoria = this.form?.codigoSubCategoria.value;
    chamado.dataInicial = this.form?.dataInicio.value;
    chamado.dataFinal = this.form?.dataFim.value;

    return chamado;
  }

  public validaAno(data: AbstractControl<any, any>){
    var dataDoCampo = data.value;
    if(dataDoCampo){
     const ano = new Date(dataDoCampo).getFullYear();
      if(ano > this.DataAtual.getFullYear() || ano < this.DataAtual.getFullYear() - 10){
        data.setValue(null);
        this.toastr.warning("Data invalida! Selecione uma data valida para pesquisar.");
      }
    }
  }

  public async getDiretoriasPorPolo(diretoriaLogada:boolean = false) {
    if(this.userLogado?.codigoPolo){
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        // this.showPesquisa = false;
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      this.getEscolasPorDiretoria();
      this.spinnerService.hide();
    }

    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria ;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formDashboard.get('codigoEscola')?.patchValue(null);
  }
  public zerarDiretoria() {
    this.diretorias = [];
    this.formDashboard.get('codigoDiretoria')?.patchValue(null);
  }

  public ativaDiretoria(){
    if(this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0){
      const diretoria = this.diretorias.find(s=>s.codigoDiretoria == this.userLogado?.codigoDiretoria )
      if(this.userLogado?.codigoDiretoria != undefined  && diretoria != undefined){
        this.formDashboard.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
      }

    }
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){

    if(this.escolas != undefined && this.userLogado?.codigoEscola != 0){
      const escola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola)
      if(escola != undefined){
        this.formDashboard.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }

    }
  }

  public async ativaPolo(){
    if(this.userLogado?.codigoPolo)
      this.formDashboard.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
      await this.getDiretoriasPorPolo();

      if(this.userLogado?.codigoDiretoria){
         this.ativaDiretoria();
      }
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getPolosAdmin() {
    this.spinnerService.show();
       var response = await lastValueFrom(this.psicologoService.getPolos()).catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.polos = response.data;
      this.spinnerService.hide();
      if(this.userLogado?.codigoPolo != 0){
        this.ativaPolo();
      }
    this.spinnerService.hide();
  }

  public async getPolosPerfilDiretor(){

    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if(this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0){
      var todasDiretoriasDoUsuario:Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria) ;
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if(this.diretorias.length == 1){
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      }else{

        if(this.userLogado?.codigoDiretoria && this.userLogado.codigoDiretoria > 0){
          this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
        }

        this.form.codigoDiretoria.enable();
      }
      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if(dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0){
      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }
    this.spinnerService.hide();
  }

  public async getPoloDiretoriaPsicologo(){

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
  }

  public async getPsicologosPolo(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.formDashboard.get('codigoPolo')!.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.lstPsicologos = response.data;
  }

  private  setUnicoPsicologo(){
    let psicologo = new Psicologo();
    psicologo.nomePsicologo = this.userLogado!.nome;
    psicologo.codigoCPF = this.userLogado!.cpf;
    this.lstPsicologos.splice(0,this.lstPsicologos.length,psicologo);
    this.formAg.psicologo.setValue(psicologo.codigoCPF);
    this.formAg.psicologo.disable();
    return;
  }

  public AbreModalAgendamento(codigoChamado:number,codigoEnvolvido:number){
    this.showModalAgendamento = true;
    this.agendamentoAtual = this.lstChamados.find(s=>s.codigoChamado == codigoChamado && s.codigoEnvolvido == codigoEnvolvido );

    if(this.agendamentoAtual!.psicologoCPF && this.lstPsicologos.length > 0){
      this.DesabilitaBotaoExcluirAgendamento = false;
      this.NomePsicologo = this.agendamentoAtual!.psicologo;
      this.DataAtendimento = this.agendamentoAtual!.dataAtendimento;
      this.HoraAtendimento = this.agendamentoAtual!.dataAtendimento;

    }else{
      this.DesabilitaBotaoExcluirAgendamento = true;
    }
  }

  public FecharModalAgendamento(){

    this.showModalAgendamento = false;
    this.submittedModal = false;
    this.resetFormModal();
    this.PesquisarChamados();
  }

  private PermitidoAgendar():boolean{
    const perfisPermitidos = [ Perfil.CoordenadorPsicologo,Perfil.GestorConviva,Perfil.Psicólogo, Perfil.GestorConviva ];
    return perfisPermitidos.includes(this.userLogado!.codigoPerfil);
  }
  public async AgendarAtendimento(){
    this.spinnerService.show();
    this.submittedModal = true;
    if(!this.PermitidoAgendar()){
      this.spinnerService.hide();
      this.toastr.error("Apenas Coordenador Psicólogos e Psicólogos podem agendar atendimentos.");
      return;
    }

    if (this.formAgendamento.invalid) {
      this.spinnerService.hide();
      return;
    }

    var agendamento = this.setBodyAgendamento();
    var response = await lastValueFrom(this.psicologoService.postAgendarConsulta(agendamento))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    if(response.data.mensagem === "Horario Reservado"){
      this.toastr.warning("Este psicólogo já possuí uma consulta neste horario, escolha outro horario.");
      this.spinnerService.hide();
    }
    else{
      this.toastr.success("Consulta agendada com sucesso");
      this.PesquisarChamados();
      this.resetFormModal();
      this.spinnerService.hide();
      this.FecharModalAgendamento();
      this.showModalAgendamento = false;
    }
  }

  public async AbreModalAtendimento(codigoChamado:number,codigoEnvolvido:number){
    this.showModalAtendimentoPsicologo = true;
    this.descricaoAtendimento = `Deseja marcar como atendido pelo Psicólogo ?`;
    this.chamadoAAtender = codigoChamado;
    this.envolvidoAAtender = codigoEnvolvido;
  }

  public FecharModalAtendimento() {
    this.showModalAtendimentoPsicologo = false;
    this.showDataAtendimento = false;
    this.formAtendimento.get('dataAtendimento')?.setValue(null);
  }

  public ShowInputDataAtendimento(){
    this.showDataAtendimento = true;
  }

  public async AtenderChamado() {

    if(this.formAtendimento.invalid){
      this.toastr.error("Data do Atendimento é obrigatória.")
      return;
    }

    const objtoAtendimento: CadAtendimento = {
      codigoChamado: this.chamadoAAtender,
      codigoEnvolvido: this.envolvidoAAtender,
      dataAtendimento:  this.formAtendimento.get('dataAtendimento')!.value,
      atendente: this.userLogado?.DadosUsuario[0].CodigoPerfil,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };


    this.FecharModalAtendimento();
    this.spinnerService.show();
    const result = await lastValueFrom(await this.atendimento.setCadastrarAtendimento(objtoAtendimento))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Atendimento do Psicólogo feito com Sucesso.');
    this.PesquisarChamados();
    this.spinnerService.hide();
  }

  public setBodyAgendamento(){
    var agendamento = new CriacaoAgendamento();
    agendamento.codigoChamado = this.agendamentoAtual!.codigoChamado;
    agendamento.codigoDiretoria = this.agendamentoAtual!.codigoDiretoria;
    agendamento.codigoEnvolvido = this.agendamentoAtual!.codigoEnvolvido;
    agendamento.cpfPsicologo = this.formAg.psicologo.value;
    agendamento.dataAtendimentoString = this.concatenaDataHora(this.formAg.dataAgendada.value,this.formAg.horarioAgendado.value);
    agendamento.polo = this.agendamentoAtual!.polo;
    agendamento.cpfCoordenador = this.userLogado!.codigoPerfil == Perfil.Psicólogo? "0" : this.userLogado!.cpf;
    agendamento.codigoPerfil = this.atribuicoesLogin ? this.atribuicoesLogin.CodigoPerfil : this.userLogado!.codigoPerfil;
    agendamento.nomePerfil = this.atribuicoesLogin ? this.atribuicoesLogin.NomePerfil : this.userLogado!.nomePerfil ;
    return agendamento;
  }

  public getFirstName(nome:string){
    return nome.split(" ")[0];
  }

  public getDate(data?:Date){
    if(data == undefined) return;
   return formatDate(data,'dd/MM/yyyy',this.locale);
  }

  public getHour(data?:Date){
    if(data == undefined) return;
    return formatDate(data,'HH:mm',this.locale)
  }

  public concatenaDataHora(data:string,hora:string): string{
    return `${data +" " + hora}`
  }

  public getDataAtendimento(data?:Date){

    if(!data){
      return "dd/mm/aaaa - 00:00";
    }
    return this.getDate(data)+ " - " + this.getHour(data)
  }

  public AbreFechaModalConfirmacao(){
    this.showModalConfirmacao = !this.showModalConfirmacao;
  }

  public async getCategorias() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.categoriaService.getCategoriasWithSub())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.categorias = response.data;
    this.spinnerService.hide();
  }

  public async ExcluirAgendamento(codigoChamado:number,codigoEnvolvido:number){
    this.spinnerService.show();
    const body = new ExclusaoAgendamento();
    body.codigoChamado = codigoChamado;
    body.codigoEnvolvido = codigoEnvolvido;
    var response = await lastValueFrom(this.psicologoService.postExcluirAgendamento(body))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success("Agendamento cancelado com sucesso!");
    this.AbreFechaModalConfirmacao();
    this.FecharModalAgendamento();
    this.PesquisarChamados();
    this.ZerarCamposModal();
    this.spinnerService.hide();
  }

  public ZerarCamposModal(){
    this.DataAtendimento = undefined;
    this.HoraAtendimento = undefined;
    this.NomePsicologo = undefined;
    this.formAg.dataAgendada.setValue(null);
    this.formAg.horarioAgendado.setValue(null);
    this.formAg.psicologo.setValue(null);
  }

  public OnChangePolo(){

    const codigoPolo = this.formDashboard.get('codigoPolo')?.value;
    if(this.userLogado !== null)
    {
      this.userLogado.codigoPolo = codigoPolo == null ? 0 : codigoPolo;
      this.authService.setUser(this.userLogado);
    }

    if(codigoPolo != null ){
      this.getDiretoriasPorPolo();
    }else{
      this.zerarDiretoria();
    }
    this.showPesquisa = false;
    this.lstChamados = [];
    this.zerarEscola();
  }

  public resetFormModal(){
    this.formAg.psicologo.reset();
    this.formAg.dataAgendada.reset();
    this.formAg.horarioAgendado.reset();
    this.DataAtendimento = undefined;
    this.HoraAtendimento = undefined;
    this.NomePsicologo = undefined;
  }

  public async trocaDiretoria(){
    const codigoDiretoria = this.formDashboard.get('codigoDiretoria')?.value;
    if(this.userLogado !== null && codigoDiretoria != null)
    {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if(codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
      this.form.codigoEscola.setValue(null);
  }

  onChangeEscola(){
    const codigoEscola = this.formDashboard.get('codigoEscola')?.value;
    if(this.userLogado !== null && codigoEscola != null)
    {
      this.userLogado.codigoEscola = codigoEscola == null ? 0 : codigoEscola;
      this.authService.setUser(this.userLogado);
    }
  }

  public async getEscolasPorDiretoria(){
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = this.form.codigoDiretoria.value == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if(this.form.codigoDiretoria.value){
      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(this.form.codigoDiretoria.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.escolas = response.data;
        this.ativaEscola();
        await this.PesquisarChamados();
        this.spinnerService.hide();
    }

  }

  public OrdenaListaColocandoValorNuloNoTopoEEmOrdemAlfabeticaOutrosItens(lista:Agendamentos[] ){
    return lista.sort((a:Agendamentos, b:Agendamentos) => {
      if (a.psicologo === null && b.psicologo === null) {
          return 0;
      } else if (a.psicologo === null) {
        return -1; // Move os valores nulos para o começo da lista
      } else if (b.psicologo === null) {
        return 1; // Move os valores nulos para o começo da lista
      } else {
          return a.psicologo.localeCompare(b.psicologo);
      }
    });
  }

  public async buscarAgendamentosEmAberto(){

    var objtEnvio = {
      codigoPolo:this.form.codigoPolo.value ,
      codigoEscola: this.form?.codigoEscola.value,
      codigoDiretoria:this.form?.codigoDiretoria.value
    }

   var result =  await lastValueFrom(this.agendamentoService.getAgendamentosEmAberto(objtEnvio));

   if(!result.isSucess){
    this.toastr.error(result.message);
   }else{
      this.quantidadeAgendamentosAbertos = result.data;
   }
  }

  public ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }

}
