<!-- PERFIL -->
<form [formGroup]="formPerfilAluno">
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Perfil Aluno</h2>
      <app-btn-back></app-btn-back>
    </div>
  </section>

  <section class="conteudo-principal">
    <div *ngIf="mostrarResultado">
      <div class="container-conviva page-perfil">
        <div *ngIf="!showChamados" class="time-line conteudo-perfil">
          <div class="conteudo-casos">
            <a
              href="javascript:void(0)"
              class="box-casos abertos"
              (click)="ChamadosAberto(perfilAluno, 0)">
              <p class="number-casos">
                {{ perfilAluno.chamadosNaoVisualizados }}
              </p>
              <p class="legenda-casos">Chamados em aberto</p>
            </a>
            <a
             href="javascript:void(0)"
             class="box-casos criados"
             (click)="ChamadosAberto(perfilAluno, 1)">
              <p class="number-casos">{{ perfilAluno.chamadosEncaminhados }}</p>
              <p class="legenda-casos">Chamados encaminhados</p>
            </a>
            <a
              href="javascript:void(0)"
              class="box-casos encerrados"
              (click)="ChamadosAberto(perfilAluno, 6)">
              <p class="number-casos">{{ perfilAluno.chamadosResolvidos }}</p>
              <p class="legenda-casos">Chamados encerrados</p>
            </a>
          </div>

          <div class="escola-perfil">
            <h3>
              {{ perfilAluno.escola.nomeEscola }}
            </h3>
            <div class="row-escola">
              <img
                src="../../../assets/img/icons/icon-nome-escola.svg"
                title="Diretoria de Ensino"
                alt=""
              />
              <p>Diretoria de Ensino: {{ perfilAluno.escola.nomeDiretoria }}</p>
            </div>
            <div class="row-escola">
              <img
                src="../../../assets/img/icons/icon-cie-escola.svg"
                title="Código identificador da escola (CIE)"
                alt=""
              />
              <p>
                Código identificador da escola (CIE):
                {{ perfilAluno.escola.codigoEscola }}
              </p>
            </div>
            <div class="row-escola">
              <img
                src="../../../assets/img/icons/icon-localizacao-escola.svg"
                title="localizacao"
                alt=""
              />
              <p>
                {{ perfilAluno.escola.descricaoLogradouro }}
                {{ perfilAluno.escola.descricaoEndereco }},
                {{ perfilAluno.escola.numero }} CEP:
                {{ perfilAluno.escola.cep }}
              </p>
            </div>
            <div class="row-escola">
              <img
                src="../../../assets/img/icons/icon-localizacao-escola.svg"
                title="Bairro"
                alt=""
              />
              <p>Bairro: {{ perfilAluno.escola.bairro }}</p>
            </div>
            <div class="row-escola">
              <img
                src="../../../assets/img/icons/icon-localizacao-escola.svg"
                title="Município"
                alt=""
              />
              <p>Município: {{ perfilAluno.escola.nomeMunicipio }}</p>
            </div>
            <div class="row-escola">
              <img
                src="../../../assets/img/icons/icon=tel-escola.svg"
                title="tel"
                alt=""
              />
              <p>
                {{ perfilAluno.escola.numeroTelefono }}
              </p>
            </div>
            <div class="row-escola">
              <img
                src="../../../assets/img/icons/icon-email-escola.svg"
                title="email"
                alt=""
              />
              <p>
                {{ perfilAluno.escola.email }}
              </p>
            </div>
          </div>

          <div class="dados-pessoais">
            <h3>Dados pessoais</h3>
            <p><b>CPF</b> {{ perfilAluno.aluno.cpf }}</p>
            <p><b>RG</b> {{ perfilAluno.aluno.rg }}</p>
            <p>
              <b>Data de Nascimento</b> {{ perfilAluno.aluno.dtNascimento }}
            </p>
            <p><b>E-mail</b> {{ perfilAluno.aluno.email }}</p>
          </div>
        </div>

        <div *ngIf="showChamados" class="time-line conteudo-perfil">

          <div class="conteudo-r">
            <a class="btn-perfil-voltar" (click)="Voltar()">
              <img src="../../../assets/img/icons/icon-arrow.svg" alt="Voltar">
            </a>
          </div>

          <div class="conteudo-casos" *ngIf="this.CdStChamado == 0">
            <a href="javascript:void(0)" class="box-casos abertos">
              <p class="number-casos">
                {{ perfilAluno.chamadosNaoVisualizados }}
              </p>
              <p class="legenda-casos">Chamados em aberto</p>
            </a>
          </div>

          <div class="conteudo-casos" *ngIf="this.CdStChamado == 1">
            <a href="javascript:void(0)" class="box-casos criados">
              <p class="number-casos">
                {{ perfilAluno.chamadosEncaminhados }}
              </p>
              <p class="legenda-casos">Chamados encaminhados</p>
            </a>
          </div>

          <div class="conteudo-casos" *ngIf="this.CdStChamado == 6">
            <a href="javascript:void(0)" class="box-casos encerrados">
              <p class="number-casos">
                {{ perfilAluno.chamadosResolvidos }}
              </p>
              <p class="legenda-casos">Chamados encerrados</p>
            </a>
          </div>

          <div *ngFor="let chamado of this.listaChamados; let i = index">

            <article class="card-casos">
              <input type="checkbox" checked class="checked-card-caso" id="card-caso-{{ i + 1 }}">
              <div *ngIf="this.CdStChamado==0" class="number-caso alerta">
                {{ this.qtyChamados - i }}
              </div>
              <div *ngIf="this.CdStChamado==1" class="number-caso encaminhados">
                {{ this.qtyChamados - i }}
              </div>
              <div *ngIf="this.CdStChamado==6" class="number-caso encerrados">
                {{ this.qtyChamados - i }}
              </div>

              <div class="conteudo-card-caso">

                <div class="header-caso">
                  <div class="titulo-card-caso">
                    <p>
                      {{ chamado.dsSubCategoria }}
                    </p>
                    <p>
                      <small>{{ chamado.dsTipoEnvolvido }}</small>
                    </p>
                    <p>
                      <small>{{ chamado.data }}</small>
                    </p>
                  </div>

                  <div class="btn-acoes">
                    <label for="card-caso-{{ i + 1 }}">
                      <a class="btn-view-card"></a>
                    </label>
                    <input type="checkbox" checked class="card-opcoes-card"  id="card-opcoes-card-{{ i + 1 }}">
                    <label *ngIf="this.CdStChamado !== 6" for="card-opcoes-card-{{ i + 1 }}">
                      <a class="btn-opcoes-card"></a>
                    </label>
                    <div *ngIf="(this.userLogado?.funcionalidades?.includes(4)) || (this.userLogado?.funcionalidades?.includes(6) || this.userLogado?.funcionalidades?.includes(7) || this.userLogado?.funcionalidades?.includes(8))" class="opcoes-card">
                      <a *ngIf="this.userLogado?.funcionalidades?.includes(4)"
                      href="javascript:void(0)"
                      (click)="OnExcluirChamado(chamado.cdChamado)">Excluir</a>
                      <a *ngIf="((this.CdStChamado==0)
                      && (this.userLogado?.funcionalidades?.includes(6) || this.userLogado?.funcionalidades?.includes(7) || this.userLogado?.funcionalidades?.includes(8)))
                      || ((this.CdStChamado==5 || this.CdStChamado==6) && this.userLogado?.funcionalidades?.includes(16))"
                      href="javascript:void(0)" (click)="AbrirModal(chamado.cdChamado, chamado.cdEnvolvido)">Encaminhamento</a>
                    </div>
                  </div>
                </div>

                <div class="imagem-destaque-card">
                  <ng-container *ngFor="let chamado of chamado.caminho_Arquivo">
                    <img [src]="chamado" alt="" />
                  </ng-container>
                  <div *ngIf="!chamado.caminho_Arquivo" class="imagem-destaque-card"></div>
                </div>

                <div class="info-card-caso">
                  <a href="">
                    {{ chamado.descricaoTurma }}
                  </a>
                </div>

                <div class="descricao-card-caso">
                  <p>
                    {{ chamado.dsOcorrencia }}
                  </p>
                </div>

                <div class="footer-card">
                  <div class="historico">
                    <a href="javascript:void(0)" (click)="OnVisualizarChamado(chamado.cdChamado)"> Visualizar Chamado</a>
                  </div>
                </div>
              </div>
            </article>
            <br />

          </div>
        </div>

        <aside class="sidebar sidebar-aluno">

          <div class="identificacao-perfil">
            <div *ngIf="perfilAluno.foto" class="foto-perfil {{perfilAluno.dsGravidade}}">
              <img src="data:image/jpeg;base64,{{ perfilAluno.foto }}" alt="" />
            </div>
            <div *ngIf="!perfilAluno.foto" class="iniciais-perfil {{perfilAluno.dsGravidade}}">
              <span>{{ perfilAluno.aluno.iniciais }}</span>
            </div>
            <div class="nome-perfil">
              {{ perfilAluno.aluno.nomeAluno }}
            </div>
            <div class="cargo-perfil">Estudante</div>
            <div class="data-perfil">
              {{ perfilAluno.escola.nomeEscola }}
            </div>
            <div class="data-perfil">
              {{ perfilAluno.aluno.descricaoTurma }}
            </div>
          </div>

          <div *ngIf="this.userLogado?.funcionalidades?.includes(2)">
            <div class="container-btns grupo-filter">
              <div>
                <button
                  type="button"
                  (click)="OnIncluirChamado()"
                  class="btn-action"
                >
                  <img src="../../../assets/img/icons/btn-send.svg" alt="" />
                  &nbsp; Adicionar chamado
                </button>
              </div>
            </div>
          </div>

          <div class="links-acesso-rapido">
            <h3>Links rápidos</h3>
            <h5>acesso</h5>
            <ul>
              <li *ngIf="this.userLogado?.funcionalidades?.includes(2)">
                <a href="javascript:void(0)" routerLink="/cadastrar-chamados" title="Adicionar Chamado">
                  Adicionar Chamado
                </a>
              </li>
              <li *ngIf=" this.userLogado?.funcionalidades?.includes(16)">
                <a href="javascript:void(0)" routerLink="/notificacoes">
                  Notificações
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" routerLink="/buscar-alunos">
                  Buscar Estudantes com Ocorrências
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" routerLink="/listar-chamados">
                  Tela de Envolvidos
                </a>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModal">
  <form [formGroup]="formModal">
    <div>
      <div>
        <h1 class="titulo-page">{{ this.tituloModal }}</h1>
      </div>
      <br />

      <div>
        <div class="container-btns container-btns-centrar grupo-filter">
          <div *ngIf="this.userLogado?.funcionalidades?.includes(6)">
            <button
              type="button"
              (click)="EncaminhamentoPsicologo()"
              class="btn-action"
            >
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Psicólogo
            </button>
          </div>
          <br />
          <div *ngIf="this.userLogado?.funcionalidades?.includes(7)">
            <button
              type="button"
              (click)="EncaminhamentoPolicia()"
              class="btn-action"
            >
              <img src="../../../assets/img/icons/btn-police.svg" alt="" />
              &nbsp; Rede Protetiva
            </button>
          </div>
          <br />
          <div *ngIf="this.userLogado?.funcionalidades?.includes(8)">
            <button
              type="button"
              (click)="EncaminhamentoSaude()"
              class="btn-action"
            >
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Rede Hospitalar
            </button>
          </div>
          <br />
          <div>
            <button type="button" (click)="FecharModal()" class="btn-action">
              &nbsp; Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>

<app-modal *ngIf="showModalExcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>{{this.descricaoExclusao}}</span>
    </div>
    <div class="container-btns">
      <input type="button" (click)="FecharModalExclusao()" class="btn_outline" value="Não"/>
      <input type="button" (click)="ExluirChamado(this.chamadoARemover)" class="btn_primary" value="Sim" />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalAdicionarChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Adicionar Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>Selecione abaixo.</span>
    </div>
    <div class="container-btns-centrar grupo-filter">
      <div>
        <input type="button" (click)="IncluirChamado(this.perfilAluno.aluno.ra, 0)" class="btn_primary" value="Envolvido"/>
      </div>
      <br />
      <div>
        <input type="button" (click)="IncluirChamado(this.perfilAluno.aluno.ra, 1)" class="btn_primary" value="Vítima"/>
      </div>
      <br />
      <div>
        <input type="button" (click)="IncluirChamado(this.perfilAluno.aluno.ra, 2)" class="btn_primary" value="Agressor"/>
      </div>
      <br />
      <div>
        <input type="button" (click)="FecharModalInclusao()" class="btn_outline" value="Fechar"/>
      </div>
    </div>
  </div>
</app-modal>
