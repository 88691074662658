import { ArquivoAzure } from "../arquivo/ArquivoAzure";

export class PsicologoAtividade {
    sT_APROVACAO_DIRETORIA: number;
    cD_ATIVIDADE_PSICOLOGO: number;
    nome: string;
    dT_ATIVIDADE_PSICOLOGO: Date;
    tM_ATIVIDADE_PSICOLOGO: string;
    cD_CHAMADO: number;
    tP_ATIVIDADE_REALIZADA: number;
    atividadE_REALIZADA : string;
    cD_ENVOLVIDO: number;
    nM_DSL_DIRETORIA_ORIGEM?: string;
    nM_DSL_DIRETORIA_DESTINO?: string;
    nM_DSL_ESCOLA_ORIGEM?: string;
    nM_DSL_ESCOLA_DESTINO?: string;
    hR_DSL_SAIDA?: string;
    hR_DSL_CHEGADA?: string;
    dS_RESPOSTA_PERGUNTA_OBJETIVO_ACAO?: string;
    dS_RESPOSTA_PERGUNTA_AVALIACAO_RESULTADO_ACAO?: string;
    dS_RESPOSTA_PERGUNTA_IMPACTO_ACAO_REALIZADA?: string;
    dS_SUB_CATEGORIA: string;
    statuS_CHAMADO: string;
    status: number;
    arquivos?: ArquivoAzure[];
    nM_ARQUIVO: string;
    caminhO_ARQUIVO: string;
    tP_ACAO_ATIVIDADE: number;
    
}