import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { Psicologo } from "../models/psicologo/Psicologo";
import { ListarEncaminhados } from "../models/chamados/listar-encaminhados";
import { CriacaoAgendamento } from "../models/psicologo/CriacaoAgedamento";
import { ExclusaoAgendamento } from "../models/psicologo/ExclusaoAgendamento";
import { AtendimentoPsicologos } from "../models/chamados/listar-atendimentos-psicologos";
import { ListarAtendimentos } from "../models/chamados/atendimentos-realizados";
import { VisitaPsicologo } from "../models/psicologo/VisitaPsicologo";
import { Consolidado } from "../models/relatorio/Consolidado";
import { Atividade } from "../models/psicologo/AtividadePsicologo";
import { VisitaPOC } from "../models/professor/VisitaPOC";
import { AtividadePsicologo } from "../models/psicologo/AtividadePsicologo";
import { AprovacaoPOC } from "../models/professor/AprovacaoPOC";
import { AprovacaoPsicologo } from "../models/psicologo/AprovacaoPsicologo";
import { AprovacaoPsicologoFiscal } from "../models/psicologo/AprovacaoPsicologoFiscal";
import { AtividadePsicologoOutros } from "../models/psicologo/AtividadePsicologoOutros";


@Injectable({
    providedIn: 'root'
  })

  export class PsicologoService {
    private api = ConvivaApi;

    constructor(
      private http: HttpClient,
      private router:Router)
    { }

    public getPolos(codigoDiretoria?:number):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getPolos}?codigoDiretoria=${codigoDiretoria}`)
                      .pipe(map((response: DefaultResponse) => response));
    }
    public getPoloPsicologo(cpf:string):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getPoloPsicologo}?cpf=${cpf}`)
                      .pipe(map((response: DefaultResponse) => response));
    }
    public getDiretoriaEscolasPorPolo(codigoPolo:number):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getDiretoriaEscolasPorPolo}?codigoPolo=${codigoPolo}`)
                      .pipe(map((response: DefaultResponse) => response));
    }
    public getDiretoriaPorPolo(codigoPolo:number):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getDiretoriasPorPolo}?codigoPolo=${codigoPolo}`)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarPsicologo(parametros: Psicologo):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Psicologo.setCadastrarPsicologo}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setDeletarPsicologo(codigoPsicologo:number):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Psicologo.setDeletarPsicologo}?codigoPsicologo=${codigoPsicologo}`, codigoPsicologo)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setEditarPsicologo(parametros: Psicologo):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Psicologo.setEditarPsicologo}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public getPsicologoPorCPF(cpf:string):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getPsicologoPorCPF}?cpf=${cpf}`)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public getEncaminhamentos(parametros:ListarEncaminhados):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.getEncaminhamentos,parametros)
                    .pipe(map((response: DefaultResponse) => response));
    }
    public getPsicologosPorPolo(poloId:number){
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getPsicologosPorPolo}?PoloId=${poloId}`)
      .pipe(map((response: DefaultResponse) => response));
    }
    public postAgendarConsulta(agendamento:CriacaoAgendamento){
      return this.http.post<DefaultResponse>(this.api.Psicologo.postSetAgendarConsulta,agendamento)
      .pipe(map((response: DefaultResponse) => response));
    }
    public postExcluirAgendamento(dadosAgendamento:ExclusaoAgendamento){
      return this.http.post<DefaultResponse>(this.api.Psicologo.postSetExcluirAgendamento, dadosAgendamento)
      .pipe(map((response: DefaultResponse) => response));
    }
    public getPsicologoPorCodigo(codigoPsicologo:number):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getPsicologoPorCodigo}?codigoPsicologo=${codigoPsicologo}`)
                      .pipe(map((response: DefaultResponse) => response));
    }
    public getAtendimentoPsicologos(parametros:AtendimentoPsicologos):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.getAtendimentoPsicologo,parametros)
                    .pipe(map((response: DefaultResponse) => response));
    }
    public getPrazoAtendimentos(parametros:ListarAtendimentos):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.getPrazoAtendimentos,parametros)
                    .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarVisitaPsicologo(parametros: VisitaPsicologo):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(this.api.Psicologo.setCadastrarVisitaPsicologo, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public getVisitasPsicologo(parametros: Consolidado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(this.api.Psicologo.getVisitasPsicologo, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setEditarVisitaPsicologo(parametros: VisitaPsicologo):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(this.api.Psicologo.setEditarVisitaPsicologo, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }
    public getDashboardAtividadePsicologos(parametros: Atividade):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.getDashboardAtividadePsicologo, parametros)
                      .pipe(map((response:DefaultResponse) => response));
    }
    public setExcluirAtividadePsicologo(codigoAtividadePsicologo : number):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.setExcluirAtividadePsicologo, {codigoAtividadePsicologo : codigoAtividadePsicologo} )
                      .pipe(map((response:DefaultResponse) => response));
    }
    public getDashboardAtividadePOCs(parametros: Atividade):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.getDashboardAtividadePOC, parametros)
                      .pipe(map((response:DefaultResponse) => response));
    }

    public getDashboardAtividadePsicologosFiscal(parametros: Atividade):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.getDashboardAtividadePsicologoFiscal, parametros)
                      .pipe(map((response:DefaultResponse) => response));
    }

     public setExcluirAtividadePOC(codigoAtividadePOC : number):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.setExcluirAtividadePOC, {codigoAtividadePOC : codigoAtividadePOC} )
                      .pipe(map((response:DefaultResponse) => response));
    }

    public getTurmasAprovacaoPOC(idAtividade: number):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getTurmasAprovacaoPOC}?idAtividade=${idAtividade}`)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public getTurmasAprovacaoPsicologo(idAtividade: number):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getTurmasAprovacaoPsicologo}?idAtividade=${idAtividade}`)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public aprovacaoAtividadePOC(parametros : AprovacaoPOC):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.aprovacaoAtividadePOC, parametros )
                      .pipe(map((response:DefaultResponse) => response));
    }

    public aprovacaoAtividadePsicologo(parametros : AprovacaoPsicologo):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.aprovacaoAtividadePsicologo, parametros )
                      .pipe(map((response:DefaultResponse) => response));
    }

    public aprovacaoAtividadePsicologoFiscal(parametros : AprovacaoPsicologoFiscal):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.aprovacaoAtividadePsicologoFiscal, parametros )
                      .pipe(map((response:DefaultResponse) => response));
    }

    public setCadastrarAtividadePsicologoOutros(parametros : AtividadePsicologoOutros):Observable<DefaultResponse>{
      return this.http.post<DefaultResponse>(this.api.Psicologo.setCadastrarAtividadePsicologoOutros, parametros )
                      .pipe(map((response:DefaultResponse) => response));
    }

    public getAvaliacaoFiscal(codigoPsicologo: number):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Psicologo.getAvaliacaoFiscal}?codigoPsicologo=${codigoPsicologo}`)
                      .pipe(map((response: DefaultResponse) => response));
    }

    //POC

    public setCadastrarVisitaPOC(parametros: VisitaPOC):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(this.api.Psicologo.setCadastrarVisitaPOC, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarAtividadePsicologo(parametros: AtividadePsicologo):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(this.api.Psicologo.setCadastrarAtividadePsicologo, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }


  }
