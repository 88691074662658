<form [formGroup]="formBuscar">
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Buscar Estudantes com Ocorrências</h2>
    </div>
  </section>

  <section class="conteudo-principal">
    <div class="container-conviva busca-aluno">
      <div class="conteudo-busca-aluno">
        <h3>Busca avançada</h3>

        <!-- FILTRO ESCOLA -->
        <app-filtro-padrao [template]="2" (filtroEmitter)="OnPesquisar($event)"></app-filtro-padrao>

        <!-- FILTRAR RESULTADO DA PESQUISA -->
        <div class="conteudo-search">
          <input
            type="text"
            placeholder="Buscar por Nome ou RA"
            formControlName="nomeAluno"
            name="nomeAluno"

          />
        </div>
        <!-- RESULTADO PESQUISA -->
        <div *ngIf="resultPesquisa" class="resultado-pesquisa">
          <div *ngFor="let turmas of turmasEnvolvidos; let i = index">
            <div class="conteudo-turma">
              <div class="header-turma">
                <div class="turma">
                  {{ turmas.descricaoTurma }}
                </div>
                <div class="quantidade-turma">
                  {{ turmas.envolvidos.length }}
                </div>
              </div>
              <div class="listagem-aluno-turma">
                <div *ngFor="let envolvido of turmas.envolvidos; let j = index">
                  <a
                    href="javascript:void(0)"
                    [routerLink]="['/perfil-aluno/', envolvido.ra]"
                    title="Perfil Aluno"
                    class="alunos"
                  >
                      <div *ngIf="envolvido.foto" class="img-aluno-oval {{envolvido.dsGravidade}}">
                        <img src="data:image/jpeg;base64,{{envolvido.foto}}" alt="Foto do Aluno">
                      </div>
                      <div *ngIf="!envolvido.foto" class="img-aluno {{envolvido.dsGravidade}}">
                        <span>{{ envolvido.iniciais }}</span>
                      </div>
                    <div class="nome-aluno">
                      {{ envolvido.nome }}
                      <div codigoTurmaAtual="envolvido.codigoTurma"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
