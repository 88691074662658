<form [formGroup]="formCadastrar" (submit)="AprovarAtividade()">

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Aprovação Visita {{this.tpAcaoAtividade}}</h2>
      <app-btn-back></app-btn-back>
    </div>
  </section>

  <section class="container-conviva form-registro">
    <!-- VISUALIZACAO GERAL -->
    <div class="container form-vista-psicologo visualizacao-geral">

      <div class="grupo-filter-full">
        <label>Psicólogo:</label>
        <p>{{this.atividade.nome}}</p>
      </div>

      <div class="conteudo-row">
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Data da Visita:</label>
            <p>{{getDate(this.atividade.dT_ATIVIDADE_PSICOLOGO)}}</p>
          </div>
        </div>
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Quanto tempo durou a ação?</label>
            <p>{{this.atividade.tM_ATIVIDADE_PSICOLOGO}}MN</p>
          </div>
        </div>
      </div>

      <div class="grupo-filter-full" *ngIf="this.atividade.cD_CHAMADO != null">
        <label>Ocorrências agendadas:</label>
        <p>Chamado {{this.atividade.cD_CHAMADO}} - {{this.atividade.dS_SUB_CATEGORIA}} -
          {{this.atividade.statuS_CHAMADO}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Qual atividade foi realizada?</label>
        <p>{{this.atividade.atividadE_REALIZADA}}</p>
      </div>

      <div class="conteudo-envolvidos" *ngIf="this.atividade.tP_ATIVIDADE_REALIZADA == 1 && this.atividade.cD_ENVOLVIDO != null">
        <h3>Envolvido</h3>
        <div class="envolvidos-aluno">
          <div class="perfil-aluno-chamado" *ngFor="let envolvido of lstEnvolvidos ">
            <div class="img-aluno">
              <span>{{envolvido.iniciais}}</span>
            </div>
            <div class="infor-aluno-chamado">
              <p class="nome-aluno">{{envolvido.nome}}</p>
              <p class="descricao">{{envolvido.dsTipoPessoa}} - {{envolvido.dsEnvolvido}}</p>
            </div>
          </div>
        </div>
      </div>


      <div class="conteudo-envolvidos" *ngIf="this.atividade.tP_ATIVIDADE_REALIZADA == 2" >
        <h3>Turmas</h3>
        <div class="grid-datatable">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border"
            aria-describedby="dashboard"
          >
            <thead>
              <tr>
                <th>Codigo Turma</th>
                <th>Numero Classe</th>
                <th>Tipo de Ensino</th>
                <th>Numero de Serie</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstTurmas">
                <td>{{item.cD_TURMA}}</td>
                <td>{{item.nR_CLASSE}}</td>
                <td>{{item.dS_TURMA}}</td>
                <td>{{item.nR_SERIE}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      @if(this.atividade.nM_DSL_DIRETORIA_DESTINO != null){
      <h3 class="sub-titulo-dash">Deslocamento:</h3>
      <div class="conteudo-row">
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label><b>Origem</b></label>
            <p><b>Diretoria:</b>{{this.atividade.nM_DSL_DIRETORIA_ORIGEM}}</p>
            <p><b>Escola: </b>{{this.atividade.nM_DSL_ESCOLA_ORIGEM}}</p>
            <p><b>Horário saída: </b>{{this.atividade.hR_DSL_SAIDA}}</p>
          </div>
        </div>
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label><b>Destino</b></label>
            <p><b>Diretoria: </b></p>
            <p><b>Escola: </b>{{this.atividade.nM_DSL_ESCOLA_DESTINO}}</p>
            <p><b>Horário chegada: </b>{{this.atividade.hR_DSL_CHEGADA}}</p>
            <p><b>Tempo de Deslocamento:</b> {{getTempoDeslocamento(atividade.hR_DSL_SAIDA, atividade.hR_DSL_CHEGADA)}}</p>
          </div>
        </div>
      </div>
    }

      <div class="grupo-filter-full">
        <label>Qual o objetivo da ação?</label>
        <p>{{this.atividade.dS_RESPOSTA_PERGUNTA_OBJETIVO_ACAO}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Como você avalia o resultado da ação realizada?</label>
        <p>{{this.atividade.dS_RESPOSTA_PERGUNTA_AVALIACAO_RESULTADO_ACAO}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Qual o objetivo da ação?</label>
        <p>{{this.atividade.dS_RESPOSTA_PERGUNTA_IMPACTO_ACAO_REALIZADA}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Anexos</label>
        @for (item of this.atividade.arquivos; track $index) {
          <a
          href="javascript:void(0)"
          class="icon-arquivo no-wrap"
          (click)="abrirEmNovaAba(item.caminhoAzure)"
          style="white-space: nowrap;"
        >
        {{ getShortenedName(item.nome) }}
        </a>
        }
    </div>
    </div>
    <!-- END VISUALIZACAO GERAL -->

    <div class="container form-aprovacao-diretor">

      <div class="grupo-filter-full">
        <label>
          Parabéns! A sua ação foi muito boa e certamente ajudará bastante na melhoria do clima e convivência escolar.
          <input
          type="checkbox"
          id="checkbox1"
          (change)="onChange1()"
          [(ngModel)]="checkbox1"
          [ngModelOptions]="{standalone: true}">

        </label>
      </div>
      <div class="grupo-filter-full">
        <label>
          Temos muitas coisas legais na sua ação! Mas gostaria de fazer algumas sugestões para as próximas, tudo bem?
          <input
          type="checkbox"
          id="checkbox2"
          (change)="onChange2()"
          [(ngModel)]="checkbox2"
          [ngModelOptions]="{standalone: true}">
        </label>
      </div>
      <div class="grupo-filter-full">
        <label>
          Acredito que esta ação pode não ter sido muito muito efetiva. Vamos repensá-la e refazê-la?
          <input
          type="checkbox"
          (change)="onChange3()"
          id="checkbox3"
          [(ngModel)]="checkbox3"
          [ngModelOptions]="{standalone: true}">

        </label>
      </div>
      <div class="textarea-container" *ngIf="checkbox2 || checkbox3">
        <textarea
        placeholder="Responda aqui"
        rows="5"
        cols="33"
        formControlName="dsAprovar"
        name="dsAprovar"
        >
      </textarea>
      </div>
      <div class="grupo-filter-full" *ngIf="(checkbox2 || checkbox3)
      && this.atividade.cD_CHAMADO != null
      && this.atividade.status != 4">
        <label>
          Todas as tratativas necessárias a este chamado foram feitas e ele pode ser encerrado?
        </label>
        <div class="container-btns-encerrar-chamado">
          <input
              type="radio" name="flexRadioDefault"
              (click)="AbreFechaModalConfirmacao(this.atividade.cD_CHAMADO)"
              class="encerrar-sim"
              value="Sim"
            />
        </div>
      </div>

      <div class="container-btns-visitas">
        @if(this.tipoAprovacao){
          <input type="button" class="btn_primary" (click)="AprovarAtividade()" value="Aprovar">
          }@else{
            <input type="button" class="btn_primary" (click)="AprovarAtividade()" value="Reprovar">
          }
      </div>

    </div>

  </section>
</form>
  <!-- Modal -->
  <app-modal *ngIf="showModalConfirmacao">
    <div class="container-modal form-registro modal-envolvidos">
      <h1 class="title-ocorrencia">Excluir</h1>
      <div>
        <p>Deseja fechar o chamado?</p>
      </div>
      <div class="container-btns">
        <input type="button" (click)="AbreFechaModalConfirmacao()" class="btn_outline" value="Não"/>
        <input type="submit" (click)="ConcluirChamado()" class="btn_primary" value="Sim" />
      </div>
    </div>
  </app-modal>
